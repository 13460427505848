import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/holistic.png';
import industryImage from './assets/industry_specific.png';
import toolsImage from './assets/advanced.png';
import chooseUsImage from './assets/choose_us.png';

const FunctionalTesting = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <header className="mb-16 text-center">
          <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
            Functional Testing Services
          </h1>
          <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
            At Firebucks, we provide comprehensive Functional Testing services designed to ensure the highest quality and reliability of your software applications.
          </p>
        </header>

        <Section
          title="Holistic Testing Approach"
          image={holisticImage}
          alt="Holistic Testing Approach"
        >
          <p className="text-lg mb-6">Our Functional Testing services cover a wide range of activities, including:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle} title="Requirement Analysis">
              Thoroughly understanding and documenting your business requirements to create precise test cases.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Test Case Design">
              Developing detailed test cases that cover all functional aspects of your application, ensuring comprehensive test coverage.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Test Execution">
              Utilizing both manual and automated testing techniques to execute test cases and identify defects efficiently.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Defect Management">
              Tracking, managing, and resolving defects to ensure your software meets the highest standards of quality.
            </ListItem>
          </ul>
        </Section>

        <Section
          title="Industry-Specific Expertise"
          image={industryImage}
          alt="Industry-Specific Expertise"
          reverse
        >
          <p className="text-lg">
            Our team possesses deep expertise across various industries, including healthcare, fintech, blockchain, retail, and SaaS enterprises. This enables us to provide tailored testing solutions that meet the unique requirements of each sector.
          </p>
        </Section>

        <Section
          title="Advanced Tools and Techniques"
          image={toolsImage}
          alt="Advanced Tools and Techniques"
        >
          <p className="text-lg mb-6">We leverage state-of-the-art tools and technologies to enhance the effectiveness of our Functional Testing services:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle}>Automated Testing Frameworks</ListItem>
            <ListItem icon={faCheckCircle}>Continuous Integration/Continuous Deployment (CI/CD) Pipelines</ListItem>
            <ListItem icon={faCheckCircle}>API Testing</ListItem>
            <ListItem icon={faCheckCircle}>Mobile and Web Application Testing</ListItem>
          </ul>
        </Section>

        <Section
          title="Why Choose Firebucks?"
          image={chooseUsImage}
          alt="Why Choose Firebucks"
          reverse
        >
          <p className="text-lg mb-6">Partner with Firebucks for your Functional Testing needs to benefit from:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle} title="Experienced Team">
              A team of seasoned professionals with extensive industry knowledge and expertise.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Proven Methodologies">
              Utilization of best practices and advanced testing methodologies to deliver high-quality results.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Client-Centric Approach">
              Customized testing solutions tailored to meet your specific business requirements.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Innovative Solutions">
              Implementation of cutting-edge tools and technologies to enhance testing efficiency and effectiveness.
            </ListItem>
          </ul>
        </Section>

        <div className="mt-16 text-center">
          <p className="text-xl text-gray-700 mb-8">
            Contact us today to learn more about our Functional Testing services and how we can help you achieve your quality assurance goals.
          </p>
          <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children, image, alt, reverse }) => (
  <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
    <div className="w-full md:w-1/2 mb-8 md:mb-0">
      <img src={image} alt={alt} className="w-full h-auto rounded-lg  " />
    </div>
    <div className="w-full md:w-1/2 md:px-8">
      <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
      {children}
    </div>
  </section>
);

const ListItem = ({ icon, title, children }) => (
  <li className="flex items-start">
    <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3" />
    <div>
      {title && <strong className="text-lg">{title}:</strong>} {children}
    </div>
  </li>
);

export default FunctionalTesting;