import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const WebServicesTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Web Services Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure your web services are reliable, secure, and performant with Firebucks' comprehensive Web Services Testing services. Our team of experts validates your APIs and web services to guarantee seamless integration and functionality.
                    </p>
                </header>

                <Section
                    title="Comprehensive Testing Process"
                    image={holisticImage}
                    alt="Comprehensive Testing Process"
                >
                    <p className="text-lg mb-6">Our Web Services Testing includes:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Functional Testing">
                            Verifying that your web services meet specified functional requirements.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Testing">
                            Ensuring your services can handle expected load and perform under stress conditions.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Testing">
                            Identifying vulnerabilities and ensuring your web services are secure against threats.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Interoperability Testing">
                            Validating that your services work seamlessly across different platforms and devices.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Compliance Testing">
                            Ensuring your web services comply with industry standards and regulations.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Techniques"
                    image={toolsImage}
                    alt="Advanced Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We utilize the latest tools and methodologies for thorough Web Services Testing:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>API testing tools for functional and load testing</ListItem>
                        <ListItem icon={faCheckCircle}>Security testing tools for vulnerability assessment</ListItem>
                        <ListItem icon={faCheckCircle}>Performance testing tools to simulate high-load conditions</ListItem>
                        <ListItem icon={faCheckCircle}>Interoperability testing frameworks to ensure cross-platform compatibility</ListItem>
                        <ListItem icon={faCheckCircle}>Compliance checkers to validate adherence to standards</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Web Services Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            Skilled testers proficient in the latest web services testing tools and techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            Extensive testing across functionality, performance, security, interoperability, and compliance.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Detailed Reporting">
                            Providing actionable insights and recommendations for improving your web services.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Proven Track Record">
                            Successful testing engagements with clients across various industries.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Web Services Testing services and how we can help ensure the reliability and security of your web services.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default WebServicesTesting;