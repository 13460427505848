import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import thoroughTestingImage from './assets/comprehensive.png'
import advancedToolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const UserAcceptanceTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        User Acceptance Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure your product meets business requirements and user expectations with Firebucks' comprehensive User Acceptance Testing (UAT) services. Our team guarantees that your application is ready for deployment and aligns with your strategic goals.
                    </p>
                </header>

                <Section
                    title="Thorough Testing Process"
                    image={thoroughTestingImage}
                    alt="Thorough Testing Process"
                >
                    <p className="text-lg mb-6">Our UAT services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Requirements Validation">
                            Confirming that the application meets all specified requirements and business needs.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="User Scenario Testing">
                            Testing the application using real-world scenarios to ensure it behaves as expected under typical user conditions.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Defect Identification">
                            Identifying and documenting any defects or issues that need to be addressed before the final release.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Feedback Collection">
                            Gathering feedback from end users to ensure their expectations are met.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Techniques"
                    image={advancedToolsImage}
                    alt="Advanced Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We employ the latest tools and methodologies to conduct thorough UAT:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Collaboration tools for effective communication and feedback collection</ListItem>
                        <ListItem icon={faCheckCircle}>Defect tracking systems to manage and resolve issues efficiently</ListItem>
                        <ListItem icon={faCheckCircle}>Automated testing tools for repetitive user scenario testing</ListItem>
                        <ListItem icon={faCheckCircle}>Manual testing to simulate real-world user interactions</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your UAT needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            Skilled testers proficient in the latest UAT tools and techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            Extensive testing across requirements validation, user scenarios, defect identification, and feedback collection.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="User-Centric Approach">
                            Focusing on real user needs and feedback to ensure your application meets expectations.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Detailed Reporting">
                            Providing actionable insights and recommendations for improving your application before release.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our User Acceptance Testing services and how we can help ensure your application's success.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default UserAcceptanceTesting;