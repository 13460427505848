import React from 'react'; 
import transformationConsultingImage from './assets/transformation_consulting2.png'; // Ensure this image exists
import transformationConsultingBackground from './assets/tranformational_consulting_background.png';
import ContactButton from './ContactButton';
import digitalTransformationImage from './assets/digital_transformation_image.png';
import processOptimizationImage from './assets/process_optimization_image.png';
import changeManagementImage from './assets/change_management_image.png';

const TransformationConsulting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <header 
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ backgroundImage: `url(${transformationConsultingBackground})`, backgroundPosition: 'center' }}
                >
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Transformation Consulting Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto ">
                        Delivering excellence in software transformation for over a decade, we craft tailored IT testing solutions that integrate seamlessly into your business. 
                    </p>
                </header>
                
                <Section
                    title="Strategic Transformation"
                    image={transformationConsultingImage}
                    alt="Transformation Consulting"
                    className="container mx-auto"
                >
                    <p className="text-lg text-left mb-6">
                        At the heart of our Transformation Consulting services is a commitment to redefining your IT capabilities. From implementing cutting-edge automated testing frameworks that reduce deployment cycles by up to 50%, to streamlining complex software architectures for enterprise-grade applications, we bring a hands-on approach to solving real-world challenges. Our track record includes delivering scalable testing solutions for industries such as healthcare, finance, and e-commerce—each with zero critical post-deployment defects.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Consulting Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-justify">
                            <Card
                                image={digitalTransformationImage}
                                title="Digital Transformation"
                                description="Enable your organization to thrive in the digital age with our rigorous end-to-end testing solutions. Whether integrating APIs, deploying microservices, or scaling cloud-native applications, our methodologies ensure faultless performance under real-world conditions. Experience error-free digital transformation powered by automated regression testing and AI-driven bug tracking."
                            />
                            <Card
                                image={processOptimizationImage}
                                title="Process Optimization"
                                description="Accelerate time-to-market and cut operational inefficiencies with our tailored testing processes. By leveraging advanced CI/CD pipelines and environment-agnostic testing strategies, we optimize resource allocation and enhance software delivery by up to 40%. Our solutions are crafted to uncover hidden bottlenecks, ensuring smoother workflows and optimal software health."
                            />
                            <Card
                                image={changeManagementImage}
                                title="Change Management"
                                description="Transition seamlessly through organizational changes with our robust testing expertise. From migrating legacy systems to integrating AI and ML modules, we ensure each transition is meticulously validated through stress testing, user simulation, and predictive analytics. Trust our proven frameworks to manage risk, reduce downtime, and deliver measurable outcomes."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default TransformationConsulting;
