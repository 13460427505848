import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import comprehensiveImage from './assets/comprehensive.png'
import additionalImage from './assets/additional.png'; 
import provenImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const AIMLModelTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        AI/ML Model Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure the accuracy, reliability, and fairness of your AI/ML models with Firebucks' AI/ML Model Testing services. Our comprehensive testing strategies evaluate models against a range of criteria to confirm they meet your business needs and ethical standards.
                    </p>
                </header>

                <Section
                    title="Comprehensive Testing Framework"
                    image={comprehensiveImage}
                    alt="Comprehensive Testing Framework"
                >
                    <p className="text-lg mb-6">Our AI/ML Model Testing services encompass:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Performance Testing">
                            Assessing the model's accuracy, precision, recall, and other performance metrics under various conditions.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Validation Testing">
                            Verifying that the model performs as intended on new, unseen data and in real-world scenarios.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Bias and Fairness Audits">
                            Identifying and mitigating potential biases in models to ensure fairness and transparency.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Robustness and Security Testing">
                            Evaluating the model's resilience against attacks, such as data poisoning or adversarial attacks.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Methodologies"
                    image={provenImage}
                    alt="Advanced Tools and Methodologies"
                    reverse
                >
                    <p className="text-lg mb-6">We employ the latest tools and methodologies to provide thorough testing of AI/ML models:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Using automated testing frameworks to execute a large number of tests efficiently.</ListItem>
                        <ListItem icon={faCheckCircle}>Applying statistical techniques to understand model behavior under varied datasets and conditions.</ListItem>
                        <ListItem icon={faCheckCircle}>Implementing continuous integration/continuous deployment (CI/CD) practices for iterative testing and updates of AI models.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="End-to-End Testing Solutions"
                    image={additionalImage}
                    alt="End-to-End Testing Solutions"
                >
                    <p className="text-lg mb-6">Our end-to-end approach ensures that every aspect of your AI/ML model is tested:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Data Quality Assessment">
                            Ensuring that the training and testing data is of high quality and representative of real-world scenarios.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Model Explainability">
                            Providing insights into the model's decision-making process to ensure transparency and accountability.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Deployment Testing">
                            Testing the model within its operational environment to ensure it integrates smoothly and performs as expected.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Opt for Firebucks for your AI/ML Model Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise in AI/ML">
                            Our team has deep expertise in the latest artificial intelligence and machine learning technologies and testing practices.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Testing Strategies">
                            We tailor our testing services to meet the specific requirements of your AI/ML projects.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Commitment to Ethical Standards">
                            We ensure that all AI/ML models adhere to ethical guidelines and business objectives.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our AI/ML Model Testing services and how we can help you deploy robust, fair, and effective AI solutions.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default AIMLModelTesting;