import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import aiTestingImage from './assets/impact-based-testing.png'; // Make sure to add this image to your assets folder

const ImpactBasedTestSelection = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-6xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-teal-500 to-green-500">
                        Impact-Based Test Selection
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance your testing strategy with Firebucks' Impact-Based Test Selection services. We leverage advanced machine learning algorithms to select and prioritize test cases based on their potential impact.
                    </p>
                </header>

                <section className="flex flex-col md:flex-row items-center mb-32">
                    <div className="w-full md:w-1/2 mb-8 md:mb-0">
                        <img src={aiTestingImage} alt="AI-Powered Testing" className="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-12">
                        <h2 className="text-4xl font-bold text-gray-800 mb-6">Our Approach</h2>
                        <ul className="space-y-4">
                            <ListItem icon={faCheckCircle} title="Detailed Analysis">
                                We analyze your application's architecture and usage patterns to identify critical areas.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Machine Learning">
                                Our AI algorithms analyze historical test data to predict high-impact areas.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Adaptive Strategy">
                                Continuous learning from each test cycle refines predictions and improves over time.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Risk Reduction">
                                We prioritize test cases covering critical functionalities and user interactions.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Comprehensive Reporting">
                                Detailed insights into test case selection, prioritization, and performance.
                            </ListItem>
                        </ul>
                    </div>
                </section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks for your Impact-Based Test Selection needs and experience a smarter, more efficient approach to testing.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-blue-500 via-teal-500 to-green-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Contact Us Today
                    </button>
                </div>
            </div>
        </div>
    );
};

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-teal-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            <strong className="text-lg">{title}:</strong> {children}
        </div>
    </li>
);

export default ImpactBasedTestSelection;