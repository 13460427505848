import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import proactiveImage from './assets/comprehensive.png';
import customizedImage from './assets/additional.png';
import benefitsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const SiteReliabilityEngineering = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Site Reliability Engineering Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Maximize the reliability and efficiency of your IT infrastructure with Firebucks' Site Reliability Engineering (SRE) services. Our SRE team integrates software engineering practices into infrastructure management to automate operations and ensure scalable, highly reliable systems.
                    </p>
                </header>

                <Section
                    title="Proactive Infrastructure Management"
                    image={proactiveImage}
                    alt="Proactive Infrastructure Management"
                >
                    <p className="text-lg mb-6">Our SRE services focus on:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Automation of Operations">
                            Implementing automated solutions to reduce manual overhead and increase efficiency.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Continuous Integration/Continuous Deployment (CI/CD)">
                            Ensuring smooth and reliable software deployments through automated pipelines.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Monitoring">
                            Using advanced monitoring tools to proactively detect and resolve issues before they impact your services.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Disaster Recovery Planning">
                            Developing robust disaster recovery plans to ensure fast recovery and minimal downtime.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Customized SRE Solutions"
                    image={customizedImage}
                    alt="Customized SRE Solutions"
                    reverse
                >
                    <p className="text-lg mb-6">We tailor our SRE solutions to meet the specific needs of your organization, including:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Assessing current infrastructure and operational practices.</ListItem>
                        <ListItem icon={faCheckCircle}>Developing specific automation strategies that align with your business objectives.</ListItem>
                        <ListItem icon={faCheckCircle}>Implementing monitoring and alerting systems tailored to your operational requirements.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of SRE Practices"
                    image={benefitsImage}
                    alt="Benefits of SRE Practices"
                >
                    <p className="text-lg mb-6">Implementing SRE practices provides significant benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Enhanced System Reliability">
                            Improved system uptime and user satisfaction through proactive issue resolution and robust systems design.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Operational Efficiency">
                            Reduced operational costs due to automation and improved processes.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Scalability">
                            Systems designed to scale efficiently with your business growth.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Opt for Firebucks for your SRE needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Experienced SRE Team">
                            Our engineers have extensive experience in building and maintaining scalable, reliable, and efficient systems.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Engineering Approaches">
                            We provide solutions specifically designed to address the unique challenges and requirements of your systems.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Service Coverage">
                            From system architecture to operational management, we cover all aspects of site reliability engineering.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Site Reliability Engineering services and how we can help enhance the resilience and efficiency of your IT operations.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default SiteReliabilityEngineering;