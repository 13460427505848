import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png' 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const CloudAssurance = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Cloud Assurance Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Maximize the reliability, security, and efficiency of your cloud infrastructure with Firebucks' Cloud Assurance services. Our team ensures that your cloud deployments meet rigorous standards and best practices, helping you optimize performance and reduce risks.
                    </p>
                </header>

                <Section
                    title="Comprehensive Cloud Testing Strategy"
                    image={holisticImage}
                    alt="Comprehensive Cloud Testing Strategy"
                >
                    <p className="text-lg mb-6">Our Cloud Assurance services encompass a broad spectrum of testing and validation activities:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Cloud Security Testing">
                            Assessing the security measures in place to protect your data and applications in the cloud.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance and Scalability Testing">
                            Ensuring that your cloud solutions perform optimally under various load conditions and can scale effectively as demand increases.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Compliance Testing">
                            Verifying that your cloud deployments comply with industry standards and regulations, such as GDPR, HIPAA, or PCI DSS.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Disaster Recovery Testing">
                            Testing the effectiveness of your disaster recovery plans to ensure rapid restoration of services in case of an outage or data loss.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Technologies"
                    image={toolsImage}
                    alt="Advanced Tools and Technologies"
                    reverse
                >
                    <p className="text-lg mb-6">We employ cutting-edge tools and technologies to provide thorough Cloud Assurance:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated testing tools integrated into cloud environments</ListItem>
                        <ListItem icon={faCheckCircle}>Cloud-based monitoring tools to track performance and security in real time</ListItem>
                        <ListItem icon={faCheckCircle}>Simulation tools for testing disaster recovery and failover procedures</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Cloud Assurance needs and benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise">
                            Deep industry knowledge and technical expertise in cloud technologies and testing.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Tailored Solutions">
                            Customized testing strategies designed to meet the unique challenges and requirements of your cloud deployments.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Proven Results">
                            A track record of improving cloud infrastructure through rigorous testing and validation processes.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            Extensive testing that covers every aspect of cloud performance, security, and compliance.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Cloud Assurance services and how we can help ensure the reliability and security of your cloud infrastructure.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default CloudAssurance;