import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';
import { Link } from 'react-router-dom';

const TestMaturityModelAssessment = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        <Link to="/services/test-maturity-model-assesment">Test Maturity Model Assessment Services</Link>
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Elevate your testing processes with Firebucks' Test Maturity Model Assessment services. Our team of experts evaluates and enhances your testing operations by aligning them with industry best practices and maturity models, enabling continuous improvement and higher quality outcomes.
                    </p>
                </header>

                <Section
                    title="Comprehensive Assessment Framework"
                    image={holisticImage}
                    alt="Comprehensive Assessment Framework"
                >
                    <p className="text-lg mb-6">Our services are designed to provide a detailed assessment of your testing practices against established maturity models:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Maturity Level Identification">
                            Determining the current maturity level of your testing processes.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Gap Analysis">
                            Identifying gaps between your current practices and the desired maturity level.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Improvement Recommendations">
                            Providing actionable recommendations to elevate the maturity of your testing operations.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Methodology and Tools"
                    image={toolsImage}
                    alt="Methodology and Tools"
                    reverse
                >
                    <p className="text-lg mb-6">We utilize proven methodologies and tools to assess and improve your testing maturity:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Standard maturity models like TMMi (Test Maturity Model integration) for structured assessment.</ListItem>
                        <ListItem icon={faCheckCircle}>Custom assessment frameworks tailored to the specific needs of your organization.</ListItem>
                        <ListItem icon={faCheckCircle}>Data-driven analytics tools to support our assessments and provide insights.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of Maturity Model Assessment"
                    image={industryImage}
                    alt="Benefits of Maturity Model Assessment"
                >
                    <p className="text-lg mb-6">Undergoing a maturity model assessment provides numerous benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Enhanced Process Efficiency">
                            Streamlined testing processes lead to reduced cycle times and lower costs.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Improved Quality">
                            Higher process maturity results in better quality software and reduced defect rates.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Strategic Improvement">
                            Targeted recommendations provide a roadmap for continuous process improvement.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Choose Firebucks for your Test Maturity Model Assessment needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Deep Expertise">
                            Our team has extensive knowledge of testing best practices and maturity assessments.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Approach">
                            We tailor our assessments to meet the unique challenges and objectives of your organization.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Support">
                            From initial assessment to implementation of recommendations, we provide full support throughout the process.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Test Maturity Model Assessment services and how we can help you enhance the effectiveness of your testing operations.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default TestMaturityModelAssessment;