import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faChartLine, faQuestionCircle, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import CareerTeam from './assets/career_team-removebg-preview.png';

const Careers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* First Section */}
      <section className="flex flex-col items-center justify-between md:flex-row py-12 px-4 lg:h-1/2-screen" style={{marginTop: '75px'}}>
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-4">
            Innovating the Future of Technology
          </h1>
          <p className="text-lg text-gray-700 mb-6">
            Our company leverages cutting-edge technology and data-driven insights to create innovative solutions for the digital world.
          </p>
          <button 
            className="  text-white py-2 px-4 rounded mb-6 md:mb-0"
            onClick={handleButtonClick}
            style={{background: 'rgb(65, 105, 225)'}}
          >
            Explore open positions
          </button>
        </div>
        <div className="md:w-1/2">
          <img
            src={CareerTeam}
            alt="Team"
            className="rounded-lg max-w-[calc(100%-20px)] h-auto"
          />
        </div>
      </section>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">No Open Positions</h3>
              <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                &times;
              </button>
            </div>
            <p className="text-gray-600 mb-4">
              Currently, there are no open positions. Please send your resume to <a href="mailto:careers@firebucks.ai" className="text-primary">careers@firebucks.ai</a>
            </p>
            <button 
              onClick={closeModal} 
              className="text-white py-2 px-4 rounded w-full"
              style={{background: 'rgb(65, 105, 225)'}}
            >
              OK
            </button>
          </div>
        </div>
      )}

      {/* Our Values Section */}
      <section className="bg-gray-100 py-12 px-4 h-1/2-screen">
        <h2 className="text-4xl font-bold text-center mb-8">
          Our mission is to drive innovation and deliver exceptional technology solutions.
        </h2>
        <div className="text-center mb-8">
          <p className="text-gray-600">
            We are committed to our clients, partners, and employees, striving to deliver the best in technology and service.
          </p>
        </div>

        {/* Values Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* First value */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <FontAwesomeIcon icon={faLightbulb} className="w-12 h-12" style={{ color: 'rgb(65, 105, 225)' }} mx-auto />
            </div>
            <h3 className="text-xl font-bold mb-2">Show Innovation</h3>
            <p className="text-gray-600">
              We act with creativity and thoughtful intention, maintaining a constant focus on our objective to innovate.
            </p>
          </div>

          {/* Second value */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <FontAwesomeIcon icon={faChartLine} className="w-12 h-12" style={{ color: 'rgb(65, 105, 225)' }} mx-auto />
            </div>
            <h3 className="text-xl font-bold mb-2">Raise the Bar</h3>
            <p className="text-gray-600">
              We’re committed to delivering superior technology solutions with an unbiased, data-driven approach.
            </p>
          </div>

          {/* Third value */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <FontAwesomeIcon icon={faQuestionCircle} className="w-12 h-12" style={{ color: 'rgb(65, 105, 225)' }} mx-auto />
            </div>
            <h3 className="text-xl font-bold mb-2">Stay Curious</h3>
            <p className="text-gray-600">
              Meaningful solutions start with asking the right questions and we stay humble even as we celebrate success.
            </p>
          </div>

          {/* Fourth value */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <FontAwesomeIcon icon={faShieldAlt} className="w-12 h-12" style={{ color: 'rgb(65, 105, 225)' }} mx-auto />
            </div>
            <h3 className="text-xl font-bold mb-2">Act with Integrity</h3>
            <p className="text-gray-600">
              We follow through on commitments and back up our claims with data, building trust through open, honest answers.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Careers;
