import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import aiAnalysisImage from './assets/defect-prediction.png'; // Assume this image exists

const DefectPredictionAnalysis = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-6xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-teal-500 to-green-500">
                        Defect Prediction and Analysis
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance your software quality assurance with Firebucks' Defect Prediction and Analysis services. We leverage advanced AI and machine learning algorithms to predict potential defects early and perform root cause analysis.
                    </p>
                </header>

                <Section
                    title="AI-Driven Defect Prediction"
                    image={aiAnalysisImage}
                    alt="AI-Driven Defect Analysis"
                >
                    <p className="text-lg mb-6">Our approach integrates AI-driven tools to analyze historical defect data and identify patterns that lead to defects. We employ sophisticated models such as:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Decision trees for interpretable predictions</ListItem>
                        <ListItem icon={faCheckCircle}>Random forests for robust ensemble learning</ListItem>
                        <ListItem icon={faCheckCircle}>Neural networks for complex pattern recognition</ListItem>
                    </ul>
                    <p className="text-lg mt-6">These models are continuously trained and updated with new data, ensuring they adapt to changes in the codebase and development practices.</p>
                </Section>

                <Section
                    title="Root Cause Analysis"
                    reverse
                >
                    <p className="text-lg mb-6">We conduct root cause analysis using AI-based clustering and anomaly detection techniques. Our process includes:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Analyzing code changes and commit histories</ListItem>
                        <ListItem icon={faCheckCircle}>Examining developer activity patterns</ListItem>
                        <ListItem icon={faCheckCircle}>Pinpointing underlying causes of defects</ListItem>
                    </ul>
                    <p className="text-lg mt-6">This targeted approach helps in implementing effective corrective actions and prevents recurrence of defects.</p>
                </Section>

                <Section
                    title="Integration and Reporting"
                >
                    <p className="text-lg mb-6">Our services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Integration of defect prediction tools into existing CI/CD pipelines</ListItem>
                        <ListItem icon={faCheckCircle}>Real-time feedback for development teams</ListItem>
                        <ListItem icon={faCheckCircle}>Comprehensive reporting and dashboards</ListItem>
                        <ListItem icon={faCheckCircle}>Insights into defect trends and prediction accuracy</ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to leverage AI for defect prediction and analysis. Contact us today to learn more about our services and how we can enhance your software quality assurance processes.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-blue-500 via-teal-500 to-green-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-24 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        {image && (
            <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <img src={image} alt={alt} className="w-full h-auto rounded-lg shadow-lg" />
            </div>
        )}
        <div className={`w-full ${image ? 'md:w-1/2 md:px-8' : ''}`}>
            <h2 className="text-3xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-green-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">{children}</div>
    </li>
);

export default DefectPredictionAnalysis;