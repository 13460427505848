import React, { useEffect, useRef } from 'react';
import './companyProfile.css';  

function CompanyProfile() {
  const statsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    statsRef.current.forEach((stat) => {
      if (stat) observer.observe(stat);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="bg-gray-100 flex flex-col">
      <div className="container mx-auto px-4 flex-1 flex flex-col py-8">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 text-center">
          GLOBAL LEADER IN
        </h2>
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8 text-center">
          DIGITAL ASSURANCE & DIGITAL ENGINEERING
        </h2> 
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
          {[ 
            { value: '90%', label: 'Improvement in customer satisfaction scores' }, 
            { value: '45%', label: 'Cost reduction in Tech Operations' }, 
            { value: '7+', label: 'Verticals' },
            { value: '12+', label: 'Industry Domains' },
          ].map((stat, index) => (
            <div
              key={index}
              className="flex flex-col items-center transition-all duration-700 ease-out stat-item bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 hover:shadow-2xl"
              ref={(el) => (statsRef.current[index] = el)}
            >
              <div className="text-4xl md:text-5xl text-blue-600 mb-2">
                <i className="fas fa-chart-line"></i> {/* Replace with appropriate Font Awesome icon */}
              </div>
              <div className="text-2xl md:text-3xl font-bold text-blue-600">
                {stat.value}
              </div>
              <p className="mt-2 md:mt-4 text-lg md:text-xl font-semibold text-gray-700 text-center">
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </div>

      
    </div>
  );
}

export default CompanyProfile;
