import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import detailedEvaluationsImage from './assets/comprehensive.png';
import complianceStandardsImage from './assets/additional.png';
import benefitsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const AccessibilityTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Accessibility Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure your digital platforms are inclusive and accessible to all users, including those with disabilities, with Firebucks' Accessibility Testing services. Our comprehensive approach helps identify and resolve accessibility barriers in your web and mobile applications, aligning them with global standards like WCAG (Web Content Accessibility Guidelines).
                    </p>
                </header>

                <Section
                    title="Detailed Accessibility Evaluations"
                    image={detailedEvaluationsImage}
                    alt="Detailed Accessibility Evaluations"
                >
                    <p className="text-lg mb-6">Our accessibility testing includes a variety of assessments:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Automated Testing">
                            Utilizing leading tools to quickly identify and flag accessibility issues.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Manual Testing">
                            Conducting detailed manual reviews by accessibility experts to ensure compliance with accessibility standards.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="User Testing">
                            Engaging users with disabilities to test the applications and provide firsthand feedback on the accessibility features.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Code Review">
                            Inspecting the underlying code for semantic structure and compliance with accessibility standards.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Compliance with Standards"
                    image={complianceStandardsImage}
                    alt="Compliance with Standards"
                    reverse
                >
                    <p className="text-lg mb-6">Our testing ensures compliance with various accessibility standards:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>WCAG 2.1 levels A, AA, and AAA</ListItem>
                        <ListItem icon={faCheckCircle}>ADA (Americans with Disabilities Act)</ListItem>
                        <ListItem icon={faCheckCircle}>Section 508 of the Rehabilitation Act</ListItem>
                        <ListItem icon={faCheckCircle}>EN 301 549 in the EU</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of Accessibility Testing"
                    image={benefitsImage}
                    alt="Benefits of Accessibility Testing"
                >
                    <p className="text-lg mb-6">Implementing accessibility testing provides numerous benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Increased Reach">
                            Making your applications accessible to a wider audience, including the over 1 billion people worldwide with disabilities.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Legal Compliance">
                            Reducing the risk of legal challenges by adhering to mandatory accessibility standards.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced User Experience">
                            Improving usability for all users, which can lead to increased satisfaction and retention.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Accessibility Testing needs and benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise">
                            Our team of accessibility experts has extensive experience in making web and mobile applications accessible.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Thorough Testing">
                            We employ a combination of automated, manual, and user testing to ensure comprehensive coverage.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Custom Solutions">
                            We tailor our accessibility services to meet the specific needs of your project.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Accessibility Testing services and how we can help you ensure that your digital platforms are accessible to everyone.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default AccessibilityTesting;