import React, { useState } from 'react';
import Usha from './assets/coo.png'; 
import CTO from './assets/cto.png';
import Jeff from './assets/Jeff.jpg';
import Chris from './assets/Chris.png';
import Advisor from './assets/advisor.png';
import AgileCoach from './assets/harish_anna.png';
const testimonials = [
  {
    image: Jeff,
    name: 'Jeff Camozzi',
    designation: 'CEO Azend Inc',
    text: 'We chose Firebucks as our QA partner and they have been a key addition to our engineering team. Their efficiency and quality are top notch. They are a valued addition and I would recommend Firebucks to any company looking for highly reliable and professional QA services without a second thought',
  },
  {
    image: Chris,
    name: 'Christopher Lynch',
    designation: 'QA Manager, High Roads',
    text: 'I recommend Firebucks, as an organization I work closely with that provides consulting QA services in the US and India. CEO and Founder, Usha Kandala, and her team provide high-quality automated and manual testing services, contributing to HighRoads delivering high-quality releases and business value. If you find yourself in need of staffing up QA initiatives on a consultative basis, I recommend you consider Firebucks. I am very pleased with Usha Kandala\'s leadership and oversight, and the QA team\'s delivery.',
  },
  // Add more testimonials here
];

const TeamAndTestimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handleNextTestimonial = () => {
    setCurrentTestimonial((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrevTestimonial = () => {
    setCurrentTestimonial((prevIndex) => 
      (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* First Section: The Crew */}
      <div className="bg-gray-50 flex flex-col justify-center items-center px-6 mt-4 py-4">
        <h2 className="text-4xl font-bold mb-4 mt-4">Our Leadership Team</h2>
        <div className="max-w-7xl w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
          <a href="https://www.linkedin.com/in/ushakandala/" target="_blank" rel="noopener noreferrer" className="bg-white rounded-xl shadow-md p-6 text-center flex flex-col justify-between">
            <img
              src={Usha} // Placeholder image
              alt="Usha Kandala"
              className="w-28 h-28 mx-auto mb-4 object-contain"
            />
            <h3 className="text-lg font-bold">Usha Kandala</h3>
            <p className="text-sm text-gray-500">CEO & Founder</p>
            <p className="mt-4 text-sm text-gray-700">
            Usha is an accomplished leader with progressive experience leading globally distributed software engineering teams. She has a strong background in scaling startups, and driving digital transformation initiatives. Usha is a domain expert across SaaS Healthcare, Retail, and e-commerce, and is a 2X Founder.
            </p>
          </a>

          <a href="https://www.linkedin.com/in/vajha/" target="_blank" rel="noopener noreferrer" className="bg-white rounded-xl shadow-md p-6 text-center flex flex-col justify-between">
            <img
              src={AgileCoach} // New team member image
              alt="Agile Coach"
              className="w-28 h-28 mx-auto mb-4 object-contain"
            />
            <h3 className="text-lg font-bold">Harish Vajha</h3>
            <p className="text-sm text-gray-500">COO</p>
            <p className="mt-4 text-sm text-gray-700">
            Harish is a dynamic leader with expertise in finance, equities, and technology. He specialises in optimizing operations through agile methodologies, driving efficiency and innovation in fast-paced environments. With deep knowledge of financial markets and technology, Harish ensures operational excellence while scaling business success.
            </p>
          </a>

          <a href="https://www.linkedin.com/in/aneel-pro-max" target="_blank" rel="noopener noreferrer" className="bg-white rounded-xl shadow-md p-6 text-center flex flex-col justify-between">
            <img
              src={CTO} // Placeholder image
              alt="Aneel Panyam"
              className="w-28 h-28 mx-auto mb-4 object-contain"
            />
            <h3 className="text-lg font-bold">Aneel Panyam</h3>
            <p className="text-sm text-gray-500">CTO</p>
            <p className="mt-4 text-sm text-gray-700">
            Aneel is a technology leader with experience building and growing businesses across diverse industries including DFIR, e-commerce, and technology consulting. With expertise in AI, cybersecurity, and full-stack engineering, he blends technology and business acumen to create innovative SaaS products and achieve unique market positions.
            </p>
          </a>

          <a href="https://www.linkedin.com/in/mohansasikumar" target="_blank" rel="noopener noreferrer" className="bg-white rounded-xl shadow-md p-6 text-center flex flex-col justify-between">
            <img
              src={Advisor} // Placeholder image
              alt="Mohan Sasikumar"
              className="w-28 h-28 mx-auto mb-4 object-contain"
            />
            <h3 className="text-lg font-bold">Mohan Sasikumar</h3>
            <p className="text-sm text-gray-500">Strategic Advisor</p>
            <p className="mt-4 text-sm text-gray-700">
            Mohan Sasi Kumar is a results-driven leader with a proven track record in value creation, digital transformation, and risk management. He has secured major deals with Fortune 500 companies and generated significant cost savings in the financial sector. He also advises startups, helping them achieve multi-million dollar revenues. 
            </p>
          </a>
        </div>
      </div>

      {/* Second Section: Testimonials */}
      <div className="lg:h-[50vh] flex justify-center items-center bg-gray-900 text-white px-6 py-4">
        <div className="relative lg:max-w-4xl w-full flex justify-center items-center">
          <button className="absolute left-0 transform -translate-y-1/2 top-1/2 md:top-1/2 -translate-y-5 z-50" onClick={handlePrevTestimonial}>
            <span className="text-white text-2xl">&lt;</span>
          </button>
          <button className="absolute right-0 transform -translate-y-1/2 top-1/2 md:top-1/2 -translate-y-5 z-50" onClick={handleNextTestimonial}>
            <span className="text-white text-2xl">&gt;</span>
          </button>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ${
                index === currentTestimonial ? 'opacity-100 flex' : 'opacity-0 hidden'
              } flex-col items-center justify-center`}
              style={{ position: 'relative' }}
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-24 h-24 rounded-full mb-4"
              />
              <h3 className="text-lg font-bold">{testimonial.name}</h3>
              <p className="text-sm text-gray-400">{testimonial.designation}</p>
              <p className="mt-4 text-center text-sm max-w-lg">{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamAndTestimonials;
