import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const QESkillsetTransformation = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        QE Skillset Transformation Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Elevate your quality engineering team's capabilities with Firebucks' QE Skillset Transformation services. Our tailored training and development programs are designed to enhance the technical and strategic skills of your QE team, aligning them with the latest industry standards and technologies.
                    </p>
                </header>

                <Section
                    title="Targeted Training Programs"
                    image={holisticImage}
                    alt="Targeted Training Programs"
                >
                    <p className="text-lg mb-6">Our QE Skillset Transformation includes:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Advanced Testing Techniques">
                            Training on modern testing methodologies such as automated testing, performance testing, and security testing.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Tool Proficiency">
                            Developing expertise in leading QE tools and platforms to enhance testing efficiency and effectiveness.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Process Improvement">
                            Instruction on implementing and optimizing QE processes to maximize quality and throughput.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Soft Skills Development">
                            Enhancing communication, leadership, and problem-solving skills critical for QE roles.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Customized Learning Pathways"
                    image={toolsImage}
                    alt="Customized Learning Pathways"
                    reverse
                >
                    <p className="text-lg mb-6">We tailor our training programs to meet the specific needs of your organization:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Assessment of current skill levels to identify training needs.</ListItem>
                        <ListItem icon={faCheckCircle}>Modular training sessions designed to address specific gaps.</ListItem>
                        <ListItem icon={faCheckCircle}>Ongoing mentorship and support to ensure skill retention and application.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of Skillset Transformation"
                    image={industryImage}
                    alt="Benefits of Skillset Transformation"
                >
                    <p className="text-lg mb-6">Transforming your QE team's skillset provides numerous benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Enhanced Quality">
                            Higher skill levels lead to better testing outcomes and product quality.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Increased Efficiency">
                            Improved skills in automated tools and processes reduce time-to-market.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Greater Adaptability">
                            A more skilled team can quickly adapt to new technologies and challenges.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your QE Skillset Transformation to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Experienced Instructors">
                            Our trainers are seasoned professionals with real-world experience in quality engineering.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Curriculum">
                            We cover both technical and soft skills to fully equip your team for modern QE challenges.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Proven Track Record">
                            We have a history of successfully transforming QE teams across various industries.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our QE Skillset Transformation services and how we can help enhance the capabilities of your quality engineering team.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default QESkillsetTransformation;