import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faCode, faCog, faShieldAlt, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import devopsImage from './assets/devops.png'; // Make sure to add this image
import devopsBackground from './assets/devops_background.png';
import ContactButton from './ContactButton';
const DevOpsServices = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className=" mx-auto px-4 sm:px-6 lg:px-8">
                <header 
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ backgroundImage: `url(${devopsBackground})`, backgroundPosition: 'center' }}
                >
                    <h1 className="text-6xl font-bold text-center pb-4 mb-6 bg-clip-text text-black">
                        DevOps Services
                    </h1>
                    <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                        Transform your development and operations processes with Firebucks' comprehensive DevOps services. Our hands-on expertise enables us to streamline your workflows, enhance collaboration, and accelerate your software delivery pipeline.
                    </p>
                </header>

                <section className="flex flex-col md:flex-row items-center mb-32">
                    <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center">
                        <img src={devopsImage} alt="DevOps Services" className="w-1/2 h-auto rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-8">
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Our Comprehensive Approach</h2>
                        <ul className="space-y-4">
                            <ListItem icon={faServer} title="Infrastructure as Code (IaC)">
                                We utilize tools like Terraform and AWS CloudFormation to automate the deployment and management of your infrastructure.
                            </ListItem>
                            <ListItem icon={faCode} title="CI/CD Pipeline Optimization">
                                We leverage industry-leading tools like Jenkins, GitLab CI, and Ansible to automate and optimize your CI/CD pipelines.
                            </ListItem>
                            <ListItem icon={faCog} title="Continuous Monitoring">
                                We set up monitoring solutions using Prometheus, Grafana, and ELK Stack to provide real-time insights into your application's performance.
                            </ListItem>
                            <ListItem icon={faShieldAlt} title="DevSecOps Integration">
                                We implement security checks and vulnerability assessments into the CI/CD pipeline using tools like OWASP ZAP, Aqua Security, and Snyk.
                            </ListItem>
                            <ListItem icon={faBoxOpen} title="Containerization and Orchestration">
                                We use Docker for containerization and Kubernetes for orchestration, enabling automated deployment, scaling, and management of applications.
                            </ListItem>
                        </ul>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-orange-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            <strong className="text-lg">{title}:</strong> {children}
        </div>
    </li>
);

export default DevOpsServices;