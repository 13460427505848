import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const TestEnvironmentManagement = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Test Environment Management Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Optimize your software testing process with Firebucks' Test Environment Management services. Our dedicated approach ensures that your test environments are stable, reliable, and mimic production settings as closely as possible, providing the perfect foundation for accurate testing results.
                    </p>
                </header>

                <Section
                    title="Efficient Management and Configuration"
                    image={holisticImage}
                    alt="Efficient Management and Configuration"
                >
                    <p className="text-lg mb-6">We provide comprehensive services to manage and configure your test environments:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Environment Setup">
                            Configuring and maintaining test environments that replicate production environments to ensure that tests are realistic and results are reliable.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Resource Allocation">
                            Managing resources efficiently to ensure availability and optimal performance of test environments.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Version Control">
                            Keeping test environments up-to-date with the latest application versions and configurations.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data Management">
                            Ensuring that test data is managed effectively, keeping it secure and relevant to current testing needs.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Tools and Automation"
                    image={toolsImage}
                    alt="Tools and Automation"
                    reverse
                >
                    <p className="text-lg mb-6">We leverage the latest tools and automation techniques to enhance the management of test environments:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated provisioning tools to quickly set up and tear down test environments.</ListItem>
                        <ListItem icon={faCheckCircle}>Configuration management tools to maintain consistency across all test environments.</ListItem>
                        <ListItem icon={faCheckCircle}>Monitoring tools to track the health and usage of environments and ensure they are always ready for testing.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proactive Problem Resolution"
                    image={industryImage}
                    alt="Proactive Problem Resolution"
                >
                    <p className="text-lg mb-6">Our proactive approach includes:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Identifying and resolving configuration issues before they affect testing.</ListItem>
                        <ListItem icon={faCheckCircle}>Ensuring that environment downtimes are minimized to not disrupt the testing schedule.</ListItem>
                        <ListItem icon={faCheckCircle}>Providing ongoing support and maintenance to address any issues in real-time.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Test Environment Management needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            Experienced professionals who specialize in test environment setup, management, and optimization.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Custom Solutions">
                            Tailored strategies that align with your specific testing requirements and business objectives.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Efficiency">
                            Optimized test environments that reduce setup time, increase testing efficiency, and improve software quality.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Test Environment Management services and how we can help streamline your testing process.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default TestEnvironmentManagement;