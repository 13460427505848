import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/Firebucks_logo.png';

// Import images
import specializedEngineeringIcon from './assets/specialized_engineering_icon.png';
import coreTestingIcon from './assets/core_testing_icon.png';
import advancedAssuranceIcon from './assets/advanced_assurance_icon.png'; 
import developmentServicesIcon from './assets/developement_services_icon.png';
import staffingConsultingIcon from './assets/staffing_and_consulting_icon.png';
import devOpsIcon from './assets/devops_and_continuous_improvement_icon.png';
import transformationConsultingIcon from './assets/transformational_consulting_icon.png';
import strategicAssessmentIcon from './assets/strategic_assessment_icon.png';
import digitalTransformationIcon from './assets/digital_transformation_icon.png';
import digitalAssuranceIcon from './assets/digital_assurance_icon.png';
import productEngineeringIcon from './assets/product_engineering_icon.png';
import specializedTestingIcon from './assets/specialized_testing_icon.png';
import aiAppsIcon from './assets/ai_apps_icon.png';
import generativeAITestingIcon from './assets/generative_ai_testing_icon.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [hoveredService, setHoveredService] = useState(null);
  const [outerUlWidth, setOuterUlWidth] = useState(null);
  const [innerUlWidths, setInnerUlWidths] = useState([]);
  const navbarRef = useRef(null);
  const serviceRefs = useRef([]);
  const outerServiceRef = useRef(null);
  const [leaveTimeout, setLeaveTimeout] = useState(null);
  const [expandedService, setExpandedService] = useState(null);
  const [expandedSubService, setExpandedSubService] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      setNavbarHeight(navbarRef.current?.offsetHeight || 0);
    };

    setNavbarHeight(navbarRef.current?.offsetHeight || 0);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (hoveredService) {
      if (outerServiceRef.current && outerUlWidth === null) {
        const maxWidth = Array.from(outerServiceRef.current.children).reduce((max, child) => {
          const width = child.scrollWidth + 32;
          return width > max ? width : max;
        }, 0);
        setOuterUlWidth(maxWidth + Math.min(32, maxWidth * 0.1));
      }

      const newInnerUlWidths = serviceRefs.current.map((ref, index) => {
        if (ref && innerUlWidths[index] === undefined) {
          const maxWidth = Array.from(ref.children).reduce((max, child) => {
            const width = child.scrollWidth + 32;
            return width > max ? width : max;
          }, 0);
          return maxWidth + Math.min(32, maxWidth * 0.1);
        }
        return innerUlWidths[index] || 0;
      });
      setInnerUlWidths(newInnerUlWidths);
    }
  }, [hoveredService, outerUlWidth, innerUlWidths]);

  useEffect(() => {
    const preloadImages = (imageArray) => {
      imageArray.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

    const allImages = [
      logo,
      specializedEngineeringIcon,
      coreTestingIcon,
      advancedAssuranceIcon,
      developmentServicesIcon,
      staffingConsultingIcon,
      devOpsIcon,
      transformationConsultingIcon,
      strategicAssessmentIcon,
      digitalTransformationIcon,
      digitalAssuranceIcon,
      productEngineeringIcon,
      specializedTestingIcon,
    ];

    preloadImages(allImages);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(prevState => {
      console.log('Toggling menu:', !prevState);
      return !prevState;
    });
  };

  const handleMouseEnter = (service) => {
    if (leaveTimeout) {
      clearTimeout(leaveTimeout);
      setLeaveTimeout(null);
    }
    setHoveredService(service);
  };

  const handleMouseLeave = (service, event) => {
    if (!event.relatedTarget || !(event.relatedTarget instanceof Node) || !event.currentTarget.contains(event.relatedTarget)) {
      const timeout = setTimeout(() => {
        if (!document.querySelector('.dropdown:hover')) {
          if (hoveredService === service) {
            setHoveredService(null);
            setMenuOpen(false);
          }
        }
      }, 200);
      setLeaveTimeout(timeout);
    }
  };

  const handleOuterMouseEnter = (index, event) => {
    if (event.relatedTarget && serviceRefs.current[index]?.contains(event.relatedTarget)) {
        alert('Re-entered outer ul from inner ul');
    }
  };

  const handleOuterMouseLeave = () => {
  };

  const handleInnerMouseEnter = (outerIndex, innerIndex) => {
  };

  const handleInnerMouseLeave = () => {
  };

  const scrollToTop = () => {
    setHoveredService('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleService = (serviceName) => {
    setExpandedService(expandedService === serviceName ? null : serviceName);
    setExpandedSubService(null);
  };

  const toggleSubService = (serviceName) => {
    setExpandedSubService(expandedSubService === serviceName ? null : serviceName);
  };

  const services = [
    { 
      name: 'Product Engineering', 
      mainUrl: 'services/product-engineering',
      items: ['Development Services', 'Specialized Engineering',   'AI Apps'],
      icons: [developmentServicesIcon, specializedEngineeringIcon,   aiAppsIcon],
      outerIcon: productEngineeringIcon,
      urls: ['services/development-services', 'services/specialized-engineering',   'services/ai_engineering']
    },
    { 
      name: 'Quality Assurance', 
      mainUrl: 'services/digital-assurance',
      items: ['Essential Assurance', 'Advanced Assurance', 'Specialized Assurance', 'GenAI Assurance'], 
      icons: [coreTestingIcon, advancedAssuranceIcon, specializedTestingIcon, generativeAITestingIcon],
      outerIcon: digitalAssuranceIcon,
      urls: ['services/core-testing', 'services/advanced-assurance', 'services/specialized-testing', 'services/generative_ai_testing']
    },
   
    { 
      name: 'Digital Transformation', 
      mainUrl: 'services/digital-transformation',
      items: ['Transformation Consulting', 'Strategic Assessments', 'DevOps and Continuous Improvement'], 
      icons: [transformationConsultingIcon, strategicAssessmentIcon, devOpsIcon],
      outerIcon: digitalTransformationIcon,
      urls: ['services/transformational-consulting', 'services/strategic-assessments', 'services/devops']
    },
  ];

  return (
    <nav
      ref={navbarRef}
      className="navbarx fixed top-0 w-full z-50 bg-white border-b border-gray-200 flex items-center h-20 text-lg px-4"
    >
      {/* Logo */}
      <div className="absolute left-4 flex items-center justify-center h-18 w-48 overflow-hidden">
        <Link to="/" onClick={() => { scrollToTop(); toggleMenu(); }}>
          <img src={logo} alt="logo" className="h-full w-full px-4 object-contain" />
        </Link>
      </div>

      {/* Desktop Menu */}
      <ul className="hidden lg:flex text-[18px] lg:flex-row gap-6 h-full items-center justify-center mx-auto">
        <li className="flex items-center cursor-pointer px-4">
          <Link to="/" onClick={scrollToTop} className="hover:text-red-600 transition">Home</Link>
        </li>
        <li className="flex items-center cursor-pointer px-4">
          <Link to="/about-us" onClick={scrollToTop} className="hover:text-red-600 transition">About Us</Link>
        </li>
        <li
          className="flex items-center cursor-pointer px-4 h-full relative dropdown"
          onMouseEnter={() => handleMouseEnter('services')}
          onMouseLeave={(e) => handleMouseLeave('services', e)}
        >
          <span className="hover:text-red-600 transition">Services</span>
          {(hoveredService === 'services' || services.some(s => hoveredService === s.name.toLowerCase())) && (
            <ul
              ref={outerServiceRef}
              className="absolute py-4 left-0 bg-white border border-gray-300 shadow-lg text-left dropdown"
              style={{
                top: `${navbarHeight}px`,
                marginTop: '0px',
                paddingRight: '0px',
                paddingLeft: '0px',
                transition: 'all 0.3s ease-in-out',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                width: outerUlWidth ? `${outerUlWidth}px` : 'auto',
              }}
              onMouseEnter={() => handleMouseEnter('services')}
              onMouseLeave={(e) => handleMouseLeave('services', e)}
            >
              {services.map((service, index) => (
                <li
                  key={index}
                  className="relative"
                  onMouseEnter={(e) => {
                    handleMouseEnter(service.name.toLowerCase());
                    handleOuterMouseEnter(index, e);
                  }}
                  onMouseLeave={(e) => {
                    handleMouseLeave(service.name.toLowerCase(), e);
                    handleOuterMouseLeave();
                  }}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Link
                    to={`/${service.mainUrl}`}
                    onClick={() => {
                      scrollToTop();
                      setMenuOpen(false);
                    }}
                    className="block flex items-start py-2 px-4 text-gray-700 hover:text-red-600 transition text-left"
                  >
                    <div 
                      className="relative mr-2" 
                      style={{ width: '32px', height: '32px' }}
                    >
                      <img 
                        src={service.outerIcon} 
                        alt={`${service.name} icon`} 
                        style={{ width: '32px', height: '32px', transition: 'opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1)' }} 
                        className="absolute top-0 left-0 w-full h-full"
                      />
                    </div>
                    {service.name}
                  </Link>
                  {hoveredService === service.name.toLowerCase() && (
                    <ul
                      ref={el => serviceRefs.current[index] = el}
                      className="absolute left-full top-0 bg-white border border-gray-300 shadow-lg rounded-lg text-left dropdown"
                      style={{
                        transition: 'all 0.3s ease-in-out',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        width: innerUlWidths[index] ? `${innerUlWidths[index]}px` : 'auto',
                      }}
                      onMouseEnter={() => handleMouseEnter(service.name.toLowerCase())}
                      onMouseLeave={(e) => handleMouseLeave(service.name.toLowerCase(), e)}
                    >
                      {service.items.map((item, idx) => (
                        <li 
                          key={idx} 
                          style={{ whiteSpace: 'nowrap' }}
                          onMouseEnter={() => handleInnerMouseEnter(index, idx)}
                          onMouseLeave={handleInnerMouseLeave}
                        >
                          <Link
                            to={`/${service.urls[idx]}`}
                            onClick={() => {
                              scrollToTop();
                              setMenuOpen(false);
                            }}
                            className="block flex items-start py-2 px-4 text-gray-700 hover:text-red-600 transition text-left"
                          >
                            {item}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </li>
        <li className="flex items-center cursor-pointer px-4">
          <Link to="/careers" onClick={scrollToTop} className="hover:text-red-600 transition">Careers</Link>
        </li>
        <li className="flex items-center cursor-pointer px-4">
          <Link to="/industries" onClick={scrollToTop} className="hover:text-red-600 transition">Industries</Link>
        </li>
        <li className="flex items-center cursor-pointer px-4">
          <Link to="/contact-us" onClick={scrollToTop} className="hover:text-red-600 transition">Contact</Link>
        </li>
      </ul>

      {/* Mobile Hamburger Menu */}
      <div className="absolute right-12 flex flex-col justify-center items-center cursor-pointer lg:hidden" onClick={toggleMenu}>
        <div className={`flex flex-col justify-between w-8 h-6 ${menuOpen ? 'open' : ''}`}>
          <span className={`block h-1 bg-black transition-transform duration-300 ${menuOpen ? 'transform rotate-45 translate-y-2' : ''}`}></span>
          <span className={`block h-1 bg-black transition-opacity duration-300 ${menuOpen ? 'opacity-0' : ''}`}></span>
          <span className={`block h-1 bg-black transition-transform duration-300 ${menuOpen ? 'transform -rotate-45 -translate-y-2' : ''}`}></span>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobile && menuOpen && (
        <ul
          className="absolute left-0 w-full bg-gray-900 text-white border-t border-gray-700 shadow-lg"
          style={{ top: `${navbarHeight}px` }}
        >
          <li className="py-3 px-4 border-b border-gray-700 transition">
            <Link to="/" onClick={() => { scrollToTop(); toggleMenu(); }} className="block text-gray-300 hover:text-red-600 transition">
              Home
            </Link>
          </li>
          <li className="py-3 px-4 border-b border-gray-700 transition">
            <Link to="/about-us" onClick={() => { scrollToTop(); toggleMenu(); }} className="block text-gray-300 hover:text-red-600 transition">
              About Us
            </Link>
          </li>
          <li className={`py-3 border-b border-gray-700 transition ${expandedService === 'services' ? 'pb-0' : ''}`}>
            <span className="block text-gray-300 hover:text-red-600 transition cursor-pointer" onClick={() => toggleService('services')}>
              Services
            </span>
            {expandedService === 'services' && (
              <ul className="">
                {services.map((service, index) => (
                  <li key={index} className="border-b border-gray-700 transition">
                    <span
                      className="block text-gray-300 hover:text-red-600 transition cursor-pointer py-4"
                      onClick={() => toggleSubService(service.name)}
                    >
                      {service.name}
                    </span>
                    {expandedSubService === service.name && (
                      <ul className="">
                        {service.items.map((item, idx) => (
                          <li key={`${index}-${idx}`} className="py-3 transition">
                            <Link
                              to={`/${service.urls[idx]}`}
                              onClick={() => {
                                scrollToTop();
                                toggleMenu();
                              }}
                              className="block text-gray-300 hover:text-red-600 transition"
                            >
                              {item}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li className="py-3 px-4 border-b border-gray-700 hover:bg-gray-800 transition">
            <Link to="/careers" onClick={() => { scrollToTop(); toggleMenu(); }} className="block text-gray-300 hover:text-red-600 transition">
              Careers
            </Link>
          </li>
          <li className="py-3 px-4 border-b border-gray-700 transition">
            <Link to="/industries" onClick={() => { scrollToTop(); toggleMenu(); }} className="block text-gray-300 hover:text-red-600 transition">
              Industries
            </Link>
          </li>
          <li className="py-3 px-4 border-b border-gray-700 transition">
            <Link to="/contact-us" onClick={() => { scrollToTop(); toggleMenu(); }} className="block text-gray-300 hover:text-red-600 transition">
              Contact
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
