import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// Import images (you'll need to add these to your assets folder)
import holisticImage from './assets/holistic.png';
import toolsImage from './assets/test2.png';
import chooseUsImage from './assets/choose_us.png';

const SecurityTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Security Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        At Firebucks, we offer comprehensive Security Testing services to ensure your applications are secure and protected against potential threats. Our expertise helps safeguard your business and customer data from vulnerabilities and attacks.
                    </p>
                </header>

                <Section
                    title="Holistic Security Strategy"
                    image={holisticImage}
                    alt="Holistic Security Strategy"
                >
                    <p className="text-lg mb-6">Our Security Testing services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Vulnerability Assessment">
                            Identifying and evaluating security weaknesses in your application.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Penetration Testing">
                            Simulating real-world attacks to uncover security flaws and assess their impact.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Code Review">
                            Analyzing source code to detect and fix security issues early in the development process.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Compliance Testing">
                            Ensuring your application meets industry standards and regulatory requirements.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Techniques"
                    image={toolsImage}
                    alt="Advanced Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">Our team employs the latest tools and methodologies to provide effective security testing:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>OWASP ZAP and Burp Suite for vulnerability scanning and penetration testing</ListItem>
                        <ListItem icon={faCheckCircle}>Static and dynamic code analysis tools for comprehensive security code reviews</ListItem>
                        <ListItem icon={faCheckCircle}>Nessus and Qualys for detailed vulnerability assessments</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Security Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Experienced Team">
                            A team of security experts proficient in the latest testing tools and techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Tailored Solutions">
                            Customized security strategies to address your specific application needs.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Protection">
                            Improved security posture to safeguard your business and customer data.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Regulatory Compliance">
                            Ensuring your application meets industry standards and regulations.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Security Testing services and how we can help protect your applications from threats.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default SecurityTesting;