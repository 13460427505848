import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';
import { Link } from 'react-router-dom';

const TestTransformationServices = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        <Link to="/services/test-transformation-services">Test Transformation Services</Link>
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Revolutionize your testing processes with Firebucks' Test Transformation Services. Our strategic approach transforms your testing operations to increase efficiency, reduce costs, and improve software quality, aligning them with modern agile and DevOps practices.
                    </p>
                </header>

                <Section
                    title="Strategic Testing Overhaul"
                    image={holisticImage}
                    alt="Strategic Testing Overhaul"
                >
                    <p className="text-lg mb-6">We provide a comprehensive suite of services designed to modernize and streamline your testing environment:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Assessment and Planning">
                            Conducting thorough assessments of your current testing practices and devising strategic plans for transformation.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Process Automation">
                            Implementing automation across testing processes to increase speed and accuracy.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Integration of Tools and Systems">
                            Integrating advanced testing tools and systems to enhance test management and execution.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Training and Support">
                            Offering training and ongoing support to ensure your team is equipped for success with new testing methodologies.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Methodologies"
                    image={toolsImage}
                    alt="Advanced Methodologies"
                    reverse
                >
                    <p className="text-lg mb-6">Our transformation services incorporate the latest in testing methodologies and technologies:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Continuous Testing in CI/CD pipelines for ongoing quality assurance.</ListItem>
                        <ListItem icon={faCheckCircle}>Usage of cloud-based platforms for scalable and flexible test environments.</ListItem>
                        <ListItem icon={faCheckCircle}>Implementation of AI and machine learning for predictive test analytics.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Impact and Benefits"
                    image={industryImage}
                    alt="Impact and Benefits"
                >
                    <p className="text-lg mb-6">The impact of our Test Transformation Services is profound, offering numerous benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Increased Efficiency">
                            Streamlined processes reduce cycle times and improve productivity.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Cost Reduction">
                            Enhanced testing efficiency reduces the overall cost of quality.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Higher Quality">
                            Improved testing processes lead to higher quality software and fewer defects.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Test Transformation needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise">
                            Leverage our deep expertise in cutting-edge testing practices and technologies.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Solutions">
                            Tailored transformation strategies that fit your specific requirements and business goals.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Approach">
                            From planning and implementation to training and support, we cover all aspects of test transformation.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Test Transformation Services and how we can help you optimize your testing operations for the challenges of modern software development.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default TestTransformationServices;