import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import tailoredImage from './assets/comprehensive.png'
import advancedImage from './assets/proven.png';
import proactiveImage from './assets/additional.png';
import chooseUsImage from './assets/choose_us.png';

const CustomTechPlatformTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Custom Tech Platform Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance the reliability and performance of your bespoke technology platforms with Firebucks' Custom Tech Platform Testing services. We specialize in providing comprehensive testing solutions tailored to the unique needs of your custom-built platforms.
                    </p>
                </header>

                <Section
                    title="Tailored Testing Strategies"
                    image={tailoredImage}
                    alt="Tailored Testing Strategies"
                >
                    <p className="text-lg mb-6">Our Custom Tech Platform Testing services are designed to address the specific challenges of your platform:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Integration Testing">
                            Ensuring all components of the platform work together seamlessly.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Functional Testing">
                            Verifying that all features function according to specifications.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Testing">
                            Testing the platform under various load conditions to ensure it performs optimally.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Testing">
                            Identifying and mitigating security vulnerabilities to protect data and maintain integrity.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Technologies"
                    image={advancedImage}
                    alt="Advanced Tools and Technologies"
                    reverse
                >
                    <p className="text-lg mb-6">We use cutting-edge testing tools and technologies to provide effective and efficient testing services:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated testing frameworks to speed up the testing process and increase accuracy.</ListItem>
                        <ListItem icon={faCheckCircle}>Real-time monitoring tools to track the platform's performance continuously.</ListItem>
                        <ListItem icon={faCheckCircle}>Security assessment tools to thoroughly evaluate the platform's defenses.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proactive Problem Solving"
                    image={proactiveImage}
                    alt="Proactive Problem Solving"
                >
                    <p className="text-lg mb-6">Our proactive testing approach ensures that potential issues are identified and resolved early in the development cycle:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Continuous feedback loops with the development team to ensure quick resolution of issues.</ListItem>
                        <ListItem icon={faCheckCircle}>Regular updates and regression tests to ensure changes do not adversely affect the platform.</ListItem>
                        <ListItem icon={faCheckCircle}>Strategic test planning and execution to maximize test coverage and effectiveness.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Custom Tech Platform Testing needs and benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            Our testers are highly skilled in dealing with complex, custom technology platforms.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Service">
                            We tailor our testing services to meet the specific requirements of your platform.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Support">
                            From initial assessments to post-deployment, we provide end-to-end testing services.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Custom Tech Platform Testing services and how we can help ensure the success and reliability of your unique technology solutions.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default CustomTechPlatformTesting;