import React, { useEffect } from 'react';
import './footer.css'; // Remove this line if you no longer need the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'; // Make sure this path is correct
import InflectraLogo from './assets/inflectra-logo-transparent.png';
import SpiraCert from './assets/Spira Certified.png';

const Footer = () => {
    useEffect(() => {
        // Define ldfdr locally
        const ldfdr = window.ldfdr || function() {
            (ldfdr._q = ldfdr._q || []).push([].slice.call(arguments));
        };

        // Assign to window.ldfdr
        window.ldfdr = ldfdr;

        // Create and append the script
        const script = document.createElement('script');
        script.src = 'https://sc.lfeeder.com/lftracker_v1_LAxoEaK3RKdaOYGd.js';
        script.async = true;
        document.body.appendChild(script);
        
        // Cleanup function to remove the script
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <footer className="bg-gray-200 text-gray-900 py-10 px-5">
            <div className="flex flex-wrap justify-between mx-auto">
                <div className="flex-1 m-2">
                    <h3 className="text-xl mb-5 text-orange-600 text-left pl-8">Our Offices</h3>
                    <div className="pl-8">
                        <h4 className="flex items-center text-lg"><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-blue-600" />APAC India</h4>
                        <p className="mb-2 text-left">Bridge+, ITPB, Whitefield, Bengaluru, Karnataka 560066</p>
                    </div>
                    <div className="mt-4 pl-8">
                        <h4 className="flex items-center text-lg"><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-blue-600" />USA</h4>
                        <p className="mb-2 text-left">Charlotte, North Carolina 28217</p>
                    </div>
                </div>
                <div className="flex-1 m-2">
                    <h3 className="text-xl mb-5 text-orange-600">Our Services</h3>
                    <ul className="list-none pl-8">
                        <li className="mb-2"><a href="/services/core-testing" className="text-gray-900 hover:text-gray-600">Essential Assurance</a></li>
                        <li className="mb-2"><a href="/services/specialized-testing" className="text-gray-900 hover:text-gray-600">Specialized Assurance</a></li>
                        <li className="mb-2"><a href="/services/development-services" className="text-gray-900 hover:text-gray-600">Development Services</a></li>
                        <li className="mb-2"><a href="/services/specialized-engineering" className="text-gray-900 hover:text-gray-600">Specialized Engineering</a></li>
                        <li className="mb-2"><a href="/services/transformational-consulting" className="text-gray-900 hover:text-gray-600">Transformation Consulting</a></li>
                        <li className="mb-2"><a href="/services/strategic-assessments" className="text-gray-900 hover:text-gray-600">Strategic Assessments</a></li>
                        <li className="mb-2"><a href="/services/devops" className="text-gray-900 hover:text-gray-600">DevOps and Continuous Improvement</a></li>
                    </ul>
                </div>
                <div className="flex flex-1 flex-col">
                    <div className='flex flex-1'>
                        <div className="flex-1 m-2">
                            <h3 className="text-xl mb-5 text-orange-600">Quick Links</h3>
                            <ul className="list-none pl-8">
                                <li className="mb-2"><a href="/contact-us" className="text-gray-900 hover:text-gray-600">Contact</a></li>
                                <li className="mb-2"><a href="/careers" className="text-gray-900 hover:text-gray-600">Careers</a></li>
                                <li className="mb-2"><a href="/about-us" className="text-gray-900 hover:text-gray-600">About Us</a></li>
                            </ul>
                        </div>
                        <div className="flex-1 m-2">
                            <h3 className="text-xl mb-5 text-orange-600">Follow Us</h3>
                            <div className="flex justify-center items-center pl-6 sm:pl-0">
                                <a href="https://www.linkedin.com/company/firebucks" target="_blank" rel="noopener noreferrer" className="text-gray-900 text-6xl">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-4 w-full">
                        <a href="https://www.inflectra.com/Company/Article/inflectra-and-firebucks-announce-strategic-partner-1757.aspx" target="_blank" rel="noopener noreferrer">
                            <img src={InflectraLogo} alt="Inflectra Logo" className="w-32 h-32 mx-2" />
                        </a>
                        <a href="https://www.inflectra.com/Company/Article/inflectra-and-firebucks-announce-strategic-partner-1757.aspx" target="_blank" rel="noopener noreferrer">
                            <img src={SpiraCert} alt="Spira Certified Logo" className="w-32 h-32 mx-2" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center border-t border-gray-300 mt-8 pt-4">
                <div className="flex flex-col items-center">
                     
                    <p className="text-sm mt-2">&copy; 2025 Firebucks. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 