import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import comprehensiveImage from './assets/comprehensive.png';
import additionalImage from './assets/additional.png';
import provenImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const ApplicationSupportMaintenance = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Application Support and Maintenance Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure your applications run smoothly and efficiently with Firebucks' Application Support and Maintenance services. Our team is committed to providing ongoing support, regular updates, and proactive maintenance to enhance application performance and extend its lifecycle.
                    </p>
                </header>

                <Section
                    title="Comprehensive Support Services"
                    image={comprehensiveImage}
                    alt="Comprehensive Support Services"
                >
                    <p className="text-lg mb-6">Our support and maintenance services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Technical Support">
                            Offering round-the-clock support to address and resolve any technical issues that may arise, ensuring minimal downtime.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Application Updates">
                            Regularly updating applications to incorporate the latest features and security enhancements, keeping your software up-to-date and secure.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Monitoring">
                            Continuously monitoring application performance to identify and rectify potential issues before they impact business operations.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Preventive Maintenance">
                            Implementing preventive maintenance strategies to improve application stability and prevent future issues.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Customized Maintenance Plans"
                    image={additionalImage}
                    alt="Customized Maintenance Plans"
                    reverse
                >
                    <p className="text-lg mb-6">We tailor our maintenance plans to meet the specific needs of your business:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Customized service level agreements (SLAs) to match your operational requirements.</ListItem>
                        <ListItem icon={faCheckCircle}>Flexible maintenance schedules that minimize disruptions to your business processes.</ListItem>
                        <ListItem icon={faCheckCircle}>Detailed reporting on maintenance activities, outcomes, and performance improvements.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proven Track Record"
                    image={provenImage}
                    alt="Proven Track Record"
                >
                    <p className="text-lg mb-6">Our proven expertise in application support and maintenance ensures:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Increased application availability and reduced downtime.</ListItem>
                        <ListItem icon={faCheckCircle}>Enhanced application security and compliance with the latest standards.</ListItem>
                        <ListItem icon={faCheckCircle}>Extended application life and improved return on investment.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your application support and maintenance needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            A team of experienced IT professionals skilled in the latest technologies and best practices.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customer-Centric Approach">
                            We prioritize your needs and work closely with you to ensure your applications meet your business goals.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Support">
                            From technical issues to performance optimizations, we cover all aspects of application support and maintenance.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Application Support and Maintenance services and how we can help ensure the longevity and success of your applications.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default ApplicationSupportMaintenance;