import React from 'react';
import './detail_page.css'; // Ensure this path matches your CSS file location

const IntegrationAndDeployment = () => {
    return (
        <div className="details-container">
            <header className="details-header">
                <h1>Integration and Deployment</h1>
            </header>
            <section className="details-content">
                <p>Ensure seamless integration and deployment with our expert services. At Firebucks, we specialize in connecting complex systems and automating workflows to create a unified and efficient operational environment. Our team leverages cutting-edge technologies and industry best practices to ensure your systems are integrated smoothly and deployed securely.</p>
                <p>Our integration services cover everything from API management and microservices architecture to data migration and ETL (Extract, Transform, Load) processes. We meticulously manage the integration of disparate systems, ensuring data consistency, reliability, and interoperability across your entire technology stack. Our approach includes robust error handling, data validation, and synchronization techniques to maintain system integrity.</p>
                <p>For deployment, we utilize CI/CD (Continuous Integration/Continuous Deployment) pipelines, containerization with Docker and Kubernetes, and cloud-native technologies to ensure your applications are deployed quickly and reliably. Our expertise in cloud platforms like AWS, Azure, and Google Cloud enables us to provide scalable and resilient deployment solutions tailored to your business needs. We implement Infrastructure as Code (IaC) using tools like Terraform and Ansible to automate the provisioning and management of your deployment environments.</p>
                <p>Firebucks guarantees a comprehensive approach to integration and deployment, minimizing downtime and ensuring high availability and performance. Our DevOps practices and automated testing frameworks ensure that your deployments are smooth, repeatable, and risk-free. We also offer monitoring and logging solutions to provide real-time visibility into your system’s performance and health.</p>
                <p>Trust us to handle the complexities of system integration and application deployment, allowing you to focus on your core business objectives. Our dedicated support and maintenance services ensure that your systems remain operational and performant post-deployment.</p>
                <p>Contact us today to learn more about our integration and deployment services and how we can help you achieve operational excellence.</p>
            </section>
        </div>
    );
};

export default IntegrationAndDeployment;
