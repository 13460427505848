import './App.css';
import Navbar from './Navbar';
import Hero from './hero';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProductEngineering from './product'; // Your component for the new page
import DigitalAssurance from './digital_assurance'; // Your component for the new page
import DeliveryTransformation from './delivery_transformation';
import Aiml from './aiml';
import Footer from './footer';
import ProductIdeation from './product_ideation';
import SoftwareArchitecture from './software_architecture';
import FullStackDevelopment from './full_stack_dev';
import IntegrationAndDeployment from './integration';
import Maintenance from './maintenance';
import Agile from './agile';
import ContinuousIntegration from './continous_integration';
import DevOpsServices from './devops_practices';
import AIPoweredTestAutomation from './ai_powered_test_automation';
import ImpactBasedTestSelection from './impact_based_testing';
import DefectPredictionAnalysis from './defect-prediction-analysis';
import SelfHealingScripts from './self_headling_scripts';
import GenerativeAIModel from './generative_ai_model';
import VectorDatabases from './vector_databases';
import AIDrivenDataAnalytics from './ai-driven-dara-analytics';
import ModelMonitoringValidation from './model-monitoring-validation';
import FunctionalTesting from './functional_testing';
import AutomationTesting from './automation_testing';
import PerformanceEngineering from './performance_engineering'; 
import SecurityTesting from './security_testing';
import CompatibilityTesting from './compatibility_test';
import UiuxTesting from './uiux_testing'; 
import UserAcceptanceTesting from './user_acceptance_testing';
import WebServicesTesting from './web_services_testing';
import MobileTesting from './mobile_testing';
import CloudAssurance from './cloud_assurance';
import TestEnvironmentManagement from './test_environment_management';
import ApplicationSupportMaintenance from './application_support_maintenance'; 
import ETLTesting from './etl_testing'; 
import TestDataManagement from './test_data_management';
import CustomTechPlatformTesting from './custom_tech_platform_testing';
import TestTransformationServices from './test_transformation_services';
import TestMaturityModelAssessment from './test_maturity_model_assesment';
import AgileTransformationServices from './agile_transformation_services';
import TCOEAssessment from './tcoe_assesment';
import QESkillsetTransformation from './qe_skillset_transformation';
import SiteReliabilityEngineering from './site_reliability_engineering'; 
import CyberSecurityThreadModeling from './cyber_security_thread_modeling';
import AIMLModelTesting from './ai_ml_model_testing';
import RoboticProcessAutomation from './robotic_process_automation';
import ServiceVirtualization from './service_virtualization';
import AccessibilityTesting from './accesibility_testing';
import ERPAndCRMProductTesting from './erp_crm_product_testing';
import Industry from './industry'; 
import AboutUsz from './about_us_new';
import ContactUs from './contact_us';
import Careers from './careers';
import CoreTesting from './CoreTesting';
import AdvancedAssurance from './AdvancedAssurance';
import SpecializedTesting from './SpecializedTesting';
import StaffingConsultant from './StaffingConsulting';
import DigitalTransformation from './DigitalTransformation'; // Import the component for digital transformation
import DevelopmentServices from './DevelopmentServices'; // Import the component for development services
import SpecializedEngineering from './SpecializedEngineering'; // Import the component for specialized engineering
import TransformationConsulting from './TransformationConsulting'; // Import the component for transformation consulting
import StrategicAssessments from './StrategicAssessments'; // Import the component for strategic assessments
import AIEngineering from './ai_engineering';
import GenerativeAITesting from './generative_ai_testing';
import DevOps from './devops_practices'; // Import the component for devops
import React, { useState, useEffect } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function CustomLazyLoadComponent({ children }) {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    // Set loaded to true after component mounts and initial render
    const timer = setTimeout(() => setLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{
      filter: loaded ? 'none' : 'blur(5px)',
      transition: 'filter 0.5s ease-out',
    }}>
      <LazyLoadComponent
        visibleByDefault={loaded}
        threshold={100}
      >
        {children}
      </LazyLoadComponent>
    </div>
  );
}

function App() {
  const location = useLocation();
  const isServicesPath = location.pathname.includes('/services');
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const navbarElement = document.querySelector('.navbar');
    if (navbarElement) {
      const height = navbarElement.offsetHeight;
      setNavbarHeight(height > 0 ? height : 72); // Set default height if measurement fails
    } else {
      setNavbarHeight(72); // Default height if no navbar element is found
    }
  }, []); // Empty dependency array to run once on mount

  return (
    <div className="App">
      <Navbar />

      {isServicesPath && (
        <p style={{ marginTop: `${navbarHeight}px`, width: '100%', display: 'inline-block' }}>
          {/* Content */}
        </p>
      )}

      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/services/digital-assurance" element={
          <CustomLazyLoadComponent>
            <DigitalAssurance />
          </CustomLazyLoadComponent>
        } />
        <Route path="/services/product-engineering" element={
          <CustomLazyLoadComponent>
            <ProductEngineering />
          </CustomLazyLoadComponent>
        } />
        <Route path="/services/core-testing" element={<CustomLazyLoadComponent><CoreTesting /></CustomLazyLoadComponent>} />
        <Route path="/services/advanced-assurance" element={<CustomLazyLoadComponent><AdvancedAssurance /></CustomLazyLoadComponent>} />
        <Route path="/services/specialized-testing" element={<CustomLazyLoadComponent><SpecializedTesting /></CustomLazyLoadComponent>} />
        <Route path="/services/development-services" element={<CustomLazyLoadComponent><DevelopmentServices /></CustomLazyLoadComponent>} />
        <Route path="/services/specialized-engineering" element={<CustomLazyLoadComponent><SpecializedEngineering /></CustomLazyLoadComponent>} />
        <Route path="/services/staffing-consulting" element={<CustomLazyLoadComponent><StaffingConsultant /></CustomLazyLoadComponent>} />
        <Route path="/services/digital-transformation" element={<CustomLazyLoadComponent><DigitalTransformation /></CustomLazyLoadComponent>} />
        <Route path="/services/transformational-consulting" element={<CustomLazyLoadComponent><TransformationConsulting /></CustomLazyLoadComponent>} />
        <Route path="/services/strategic-assessments" element={<CustomLazyLoadComponent><StrategicAssessments /></CustomLazyLoadComponent>} />
        <Route path="/services/devops" element={<CustomLazyLoadComponent><DevOps /></CustomLazyLoadComponent>} />
        <Route path="/delivery-transformation" element={<CustomLazyLoadComponent><DeliveryTransformation /></CustomLazyLoadComponent>} />
        <Route path="/aiml" element={<CustomLazyLoadComponent><Aiml /></CustomLazyLoadComponent>} />
        <Route path="/product-ideation" element={<CustomLazyLoadComponent><ProductIdeation /></CustomLazyLoadComponent>} />
        <Route path="/software-architecture" element={<CustomLazyLoadComponent><SoftwareArchitecture /></CustomLazyLoadComponent>} />
        <Route path="/full-stack-development" element={<CustomLazyLoadComponent><FullStackDevelopment /></CustomLazyLoadComponent>} />
        <Route path="/integration-and-deployment" element={<CustomLazyLoadComponent><IntegrationAndDeployment /></CustomLazyLoadComponent>} />
        <Route path='/maintenance-and-support' element={<CustomLazyLoadComponent><Maintenance /></CustomLazyLoadComponent>} />
        <Route path='/agile-transformation' element={<CustomLazyLoadComponent><Agile /></CustomLazyLoadComponent>} />
        <Route path='/continous-integration' element={<CustomLazyLoadComponent><ContinuousIntegration /></CustomLazyLoadComponent>} />
        <Route path='/devops-practices' element={<CustomLazyLoadComponent><DevOpsServices /></CustomLazyLoadComponent>} />
        <Route path='/ai-powered-test-automation' element={<CustomLazyLoadComponent><AIPoweredTestAutomation /></CustomLazyLoadComponent>} />
        <Route path='/impact-based-test-selection' element={<CustomLazyLoadComponent><ImpactBasedTestSelection /></CustomLazyLoadComponent>} />
        <Route path='/defect-prediction-analysis' element={<CustomLazyLoadComponent><DefectPredictionAnalysis /></CustomLazyLoadComponent>} />
        <Route path='/self-healing-scripts' element={<CustomLazyLoadComponent><SelfHealingScripts /></CustomLazyLoadComponent>} />
        <Route path='/generative-ai-models' element={<CustomLazyLoadComponent><GenerativeAIModel /></CustomLazyLoadComponent>} />
        <Route path='/vector-databases' element={<CustomLazyLoadComponent><VectorDatabases /></CustomLazyLoadComponent>} />
        <Route path='/ai-driven-data-analytics' element={<CustomLazyLoadComponent><AIDrivenDataAnalytics /></CustomLazyLoadComponent>} />
        <Route path='/model-monitoring-validation' element={<CustomLazyLoadComponent><ModelMonitoringValidation /></CustomLazyLoadComponent>} />
        <Route path='/functional-testing' element={<CustomLazyLoadComponent><FunctionalTesting /></CustomLazyLoadComponent>} />
        <Route path='/automation-testing' element={<CustomLazyLoadComponent><AutomationTesting /></CustomLazyLoadComponent>} />
        <Route path='/performance-engineering' element={<CustomLazyLoadComponent><PerformanceEngineering /></CustomLazyLoadComponent>} />
        <Route path='/security-testing' element={<CustomLazyLoadComponent><SecurityTesting /></CustomLazyLoadComponent>} />
        <Route path='/compatibility-testing' element={<CustomLazyLoadComponent><CompatibilityTesting /></CustomLazyLoadComponent>} />
        <Route path='/uiux-testing' element={<CustomLazyLoadComponent><UiuxTesting /></CustomLazyLoadComponent>} />
        <Route path='/user-acceptance-testing' element={<CustomLazyLoadComponent><UserAcceptanceTesting /></CustomLazyLoadComponent>} />
        <Route path='/web-services-testing' element={<CustomLazyLoadComponent><WebServicesTesting /></CustomLazyLoadComponent>} />
        <Route path='/mobile-testing' element={<CustomLazyLoadComponent><MobileTesting /></CustomLazyLoadComponent>} />
        <Route path='/cloud-assurance' element={<CustomLazyLoadComponent><CloudAssurance /></CustomLazyLoadComponent>} />
        <Route path='/test-environment-management' element={<CustomLazyLoadComponent><TestEnvironmentManagement /></CustomLazyLoadComponent>} />
        <Route path='/application-support-maintenance' element={<CustomLazyLoadComponent><ApplicationSupportMaintenance /></CustomLazyLoadComponent>} />
        <Route path='/etl-testing' element={<CustomLazyLoadComponent><ETLTesting /></CustomLazyLoadComponent>} />
        <Route path='/test-data-management' element={<CustomLazyLoadComponent><TestDataManagement /></CustomLazyLoadComponent>} />
        <Route path='/custom-tech-platform-testing' element={<CustomLazyLoadComponent><CustomTechPlatformTesting /></CustomLazyLoadComponent>} />
        <Route path='/services/test-transformation-services' element={<CustomLazyLoadComponent><TestTransformationServices /></CustomLazyLoadComponent>} />
        <Route path='/services/test-maturity-model-assesment' element={<CustomLazyLoadComponent><TestMaturityModelAssessment /></CustomLazyLoadComponent>} />
        <Route path='/services/agile-transformation-services' element={<CustomLazyLoadComponent><AgileTransformationServices /></CustomLazyLoadComponent>} />
        <Route path='/services/tcoe-assesment' element={<CustomLazyLoadComponent><TCOEAssessment /></CustomLazyLoadComponent>} />
        <Route path='/services/qe-skillset-transformation' element={<CustomLazyLoadComponent><QESkillsetTransformation /></CustomLazyLoadComponent>} />
        <Route path='/site-reliability-engineering' element={<CustomLazyLoadComponent><SiteReliabilityEngineering /></CustomLazyLoadComponent>} />
        <Route path='/cyber-security-and-threat-modelling' element={<CustomLazyLoadComponent><CyberSecurityThreadModeling /></CustomLazyLoadComponent>} />
        <Route path='/ai-ml-model-testing' element={<CustomLazyLoadComponent><AIMLModelTesting /></CustomLazyLoadComponent>} />
        <Route path='/robotic-process-automation' element={<CustomLazyLoadComponent><RoboticProcessAutomation /></CustomLazyLoadComponent>} />
        <Route path='/service-virtualization' element={<CustomLazyLoadComponent><ServiceVirtualization /></CustomLazyLoadComponent>} />
        <Route path='/accessibility-testing' element={<CustomLazyLoadComponent><AccessibilityTesting /></CustomLazyLoadComponent>} />
        <Route path='/erp-and-crm-product-testing' element={<CustomLazyLoadComponent><ERPAndCRMProductTesting /></CustomLazyLoadComponent>} />
        <Route path='/industries' element={<CustomLazyLoadComponent><Industry /></CustomLazyLoadComponent>} />
        <Route path='/about-us' element={<CustomLazyLoadComponent><AboutUsz /></CustomLazyLoadComponent>} />
        <Route path='/contact-us' element={<CustomLazyLoadComponent><ContactUs /></CustomLazyLoadComponent>} />
        <Route path='/careers' element={<CustomLazyLoadComponent><Careers /></CustomLazyLoadComponent>} />
        <Route path="/services/ai_engineering" element={<CustomLazyLoadComponent><AIEngineering /></CustomLazyLoadComponent>} />
        <Route path='services/generative_ai_testing' element={<CustomLazyLoadComponent><GenerativeAITesting/></CustomLazyLoadComponent>}/>
        {/* Other routes */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;