import React from 'react'; 
import developmentServicesImage from './assets/development_services.png'; // Ensure this image exists
import developmentServicesBackground from './assets/developement_services_background.png';
import ContactButton from './ContactButton';
import AI_development_image from './assets/ai_development_image.png'; 
import CustomSoftwareDevelopmentImage from './assets/custom_software_developement_image.png';
import MobileAppDevelopmentImage from './assets/mobile_developement_image.png';
import WebDevelopmentImage from './assets/web_development_image.png';

const DevelopmentServices = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <header className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center" style={{ backgroundImage: `url(${developmentServicesBackground})`, backgroundPosition: 'bottom' }}>
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Development Services
                    </h1>
                    <p className="text-2xl text-gray-900 max-w-3xl mx-auto pt-8">
                        Transform your ideas into reality with our comprehensive development services.
                    </p>
                </header>
<br/>
                <Section
                    title="End-to-End Development"
                    image={developmentServicesImage}
                    alt="Development Services"
                    className="container mx-auto"
                >
                    <p className="text-lg text-left mb-6">
                        At Firebucks, we leverage the power of AI tools and the experience and talent of our versatile developers to ensure an impeccable development workflow. Our team is dedicated to delivering high-quality solutions that meet your business needs.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Development Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                            <Card
                                image={CustomSoftwareDevelopmentImage}
                                title="Custom Software Development"
                                description="Tailored solutions to meet your unique business needs."
                            />
                            <Card
                                image={MobileAppDevelopmentImage}
                                title="Mobile App Development"
                                description="Creating seamless mobile experiences for your users."
                            />
                            <Card
                                image={WebDevelopmentImage}
                                title="Web Development"
                                description="Building responsive and robust web applications."
                            />
                            <Card
                                image={AI_development_image}
                                title="AI Development"
                                description="Harnessing the power of AI to drive innovation and efficiency."
                            />
                        </div>
                    </div>
                </section>
            </div>
            <ContactButton />
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);
 
const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default DevelopmentServices; 