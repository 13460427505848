import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// Import images (you'll need to add these to your assets folder)
import contentCreationImage from './assets/generative-ai.png';
import codeGenerationImage from './assets/c4.png';
import customModelImage from './assets/advanced.png';

const GenerativeAIModel = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Generative AI Model Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Unlock the full potential of AI with Firebucks' comprehensive Generative AI Model services. We specialize in leveraging advanced generative models to transform various aspects of your business, from content creation to code generation, and more.
                    </p>
                </header>

                <Section
                    title="Content Creation"
                    image={contentCreationImage}
                    alt="Content Creation"
                >
                    <p className="text-lg mb-6">
                        Utilize our generative AI models to automate and enhance your content creation processes. By employing AI models like large language models (LLMs) such as GPT, we generate high-quality text that mimics human writing.
                    </p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated writing</ListItem>
                        <ListItem icon={faCheckCircle}>Content creation</ListItem>
                        <ListItem icon={faCheckCircle}>Chatbots</ListItem>
                        <ListItem icon={faCheckCircle}>Marketing copy</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Code Generation"
                    image={codeGenerationImage}
                    alt="Code Generation"
                    reverse
                >
                    <p className="text-lg mb-6">
                        Boost your development productivity with AI-assisted coding. AI models can help generate code snippets, automate repetitive coding tasks, and even provide intelligent suggestions during the development process.
                    </p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Generate code snippets</ListItem>
                        <ListItem icon={faCheckCircle}>Automate repetitive tasks</ListItem>
                        <ListItem icon={faCheckCircle}>Intelligent coding suggestions</ListItem>
                        <ListItem icon={faCheckCircle}>Maintain code quality and consistency</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Custom Model Training"
                    image={customModelImage}
                    alt="Custom Model Training"
                >
                    <p className="text-lg mb-6">
                        We offer custom model training services to tailor generative AI models to your unique business needs. By fine-tuning pre-trained models on your datasets, we enhance their performance for specific applications.
                    </p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Fine-tune pre-trained models</ListItem>
                        <ListItem icon={faCheckCircle}>Enhance performance for specific applications</ListItem>
                        <ListItem icon={faCheckCircle}>Ensure accuracy and effectiveness</ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to explore the full spectrum of generative AI applications. Our all-encompassing approach ensures that you can leverage AI to drive innovation and efficiency across various aspects of your business.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {children}
        </div>
    </li>
);

export default GenerativeAIModel;