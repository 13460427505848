import React from 'react';
import { useNavigate } from 'react-router-dom';
import advancedAssuranceBackground from './assets/advanced_assurance_background.png';
import testTransformationServicesImage from './assets/test_transformation_services_image.png';
import testModelAssessmentImage from './assets/test_model_assesment_image.png';
import agileTransformationImage from './assets/agile_transformation_image.png';
import tcoeAssessmentImage from './assets/tcoe_assesment.png';
import qeSkillsetTransformationImage from './assets/qe_skillset_transformation_image.png';
import ContactButton from './ContactButton';

const AdvancedAssurance = () => {
    const navigate = useNavigate();

    const handleCardClick = (path) => {
        navigate(`/services${path}`);
    };

    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 pb-8 pt-0 sm:px-6 lg:px-8">
                <header className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center" style={{ backgroundImage: `url(${advancedAssuranceBackground})`, backgroundPosition: 'bottom' }}>
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Advanced Assurance Services
                    </h1>
                    <p className="text-2xl text-gray-900 max-w-3xl mx-auto pt-8">
                        Explore our comprehensive assurance services designed to elevate your business.
                    </p>
                </header>
                <br/><br/>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                    <div className="flex flex-col items-center p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow cursor-pointer overflow-hidden" onClick={() => handleCardClick('/test-transformation-services')}>
                        <img src={testTransformationServicesImage} alt="Test Transformation Services" className="w-1/2 h-48 object-cover mb-4 transform transition-transform duration-300 hover:scale-110" />
                        <span className="text-center text-lg font-semibold">Test Transformation Services</span>
                    </div>
                    <div className="flex flex-col items-center p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow cursor-pointer overflow-hidden" onClick={() => handleCardClick('/test-maturity-model-assesment')}>
                        <img src={testModelAssessmentImage} alt="Test Maturity Model Assessment" className="w-1/2 h-48 object-cover mb-4 transform transition-transform duration-300 hover:scale-110" />
                        <span className="text-center text-lg font-semibold">Test Maturity Model Assessment</span>
                    </div>
                    <div className="flex flex-col items-center p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow cursor-pointer overflow-hidden" onClick={() => handleCardClick('/agile-transformation-services')}>
                        <img src={agileTransformationImage} alt="Agile Transformation" className="w-1/2 h-48 object-cover mb-4 transform transition-transform duration-300 hover:scale-110" />
                        <span className="text-center text-lg font-semibold">Agile Transformation</span>
                    </div>
                    <div className="flex flex-col items-center p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow cursor-pointer overflow-hidden" onClick={() => handleCardClick('/tcoe-assesment')}>
                        <img src={tcoeAssessmentImage} alt="TCoE Assessment" className="w-1/2 h-48 object-cover mb-4 transform transition-transform duration-300 hover:scale-110" />
                        <span className="text-center text-lg font-semibold">TCoE Assessment</span>
                    </div>
                    <div className="flex flex-col items-center p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow cursor-pointer overflow-hidden" onClick={() => handleCardClick('/qe-skillset-transformation')}>
                        <img src={qeSkillsetTransformationImage} alt="QE Skillset Transformation" className="w-1/2 h-48 object-cover mb-4 transform transition-transform duration-300 hover:scale-110" />
                        <span className="text-center text-lg font-semibold">QE Skillset Transformation</span>
                    </div>
                </div>
            </div>
            <ContactButton />
        </div>
    );
};

export default AdvancedAssurance; 