import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/c3.png';

const VectorDatabases = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Vector Databases
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Optimize the management and retrieval of high-dimensional data with Firebucks' Vector Database services. Our expertise can help you implement vector databases, crucial for AI applications such as recommendation systems and semantic search.
                    </p>
                </header>

                <Section
                    title="Efficient High-Dimensional Data Management"
                    image={holisticImage}
                    alt="Efficient High-Dimensional Data Management"
                >
                    <p className="text-lg mb-6">
                        Vector databases are designed to handle high-dimensional vectors efficiently, making them ideal for applications requiring quick similarity searches. This is essential for AI applications that need to process large volumes of data swiftly and accurately.
                    </p>
                </Section>

                <Section
                    title="AI-Powered Recommendation Systems"
                    image={toolsImage}
                    alt="AI-Powered Recommendation Systems"
                    reverse
                >
                    <p className="text-lg mb-6">
                        Vector databases can significantly enhance recommendation systems by enabling efficient similarity searches. By leveraging high-dimensional vectors, these databases can improve the accuracy and relevance of recommendations, offering a more personalized user experience.
                    </p>
                </Section>

                <Section
                    title="Semantic Search for Images and Videos"
                    image={industryImage}
                    alt="Semantic Search for Images and Videos"
                >
                    <p className="text-lg mb-6">
                        Implementing vector databases allows for advanced semantic search capabilities. For instance, using models like OpenAI's CLIP, we can enable semantic search for images and videos. This means that users can search for images or videos using natural language descriptions, significantly improving search relevance and user experience.
                    </p>
                </Section>

                <Section
                    title="Advanced AI Implementations"
                    image={chooseUsImage}
                    alt="Advanced AI Implementations"
                    reverse
                >
                    <p className="text-lg mb-6">
                        We can use vector databases for various advanced AI implementations, such as clustering similar items, anomaly detection, and more. These capabilities make vector databases a versatile tool for a wide range of AI-driven applications.
                    </p>
                </Section>

                <Section
                    title="Popular Vector Databases"
                    image={toolsImage}
                    alt="Popular Vector Databases"
                >
                    <p className="text-lg mb-6">
                        Some of the leading vector databases we can implement include:
                    </p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>ChromaDB</ListItem>
                        <ListItem icon={faCheckCircle}>Qdrant</ListItem>
                        <ListItem icon={faCheckCircle}>And others optimized for performance and scalability</ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to explore the full potential of vector databases for your AI applications. Our comprehensive approach ensures that you can leverage the best technologies to drive innovation and efficiency.
                    </p>
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Vector Database services and how we can help you implement these advanced technologies for your business needs.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {children}
        </div>
    </li>
);

export default VectorDatabases;