import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import comprehensiveImage from './assets/comprehensive.png'
import toolsImage from './assets/proven.png';
import benefitsImage from './assets/additional.png';
import chooseUsImage from './assets/choose_us.png';

const ERPandCRMProductTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        ERP and CRM Product Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance the performance and reliability of your ERP and CRM systems with Firebucks' specialized Product Testing services. Our rigorous testing protocols ensure that your ERP and CRM implementations are robust, secure, and fully aligned with your business processes and needs.
                    </p>
                </header>

                <Section
                    title="Comprehensive Testing Strategy"
                    image={comprehensiveImage}
                    alt="Comprehensive Testing Strategy"
                >
                    <p className="text-lg mb-6">Our ERP and CRM testing encompasses a wide range of critical assessments:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Functional Testing">
                            Validating all functionalities of the ERP and CRM systems to ensure they perform as intended.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Integration Testing">
                            Checking the integration of the ERP and CRM systems with other enterprise applications to ensure seamless data flow and functionality.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Testing">
                            Assessing the systems under various load conditions to ensure they can handle real-world usage scenarios.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Testing">
                            Identifying vulnerabilities within the ERP and CRM systems to safeguard sensitive business data.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Usability Testing">
                            Ensuring the systems are intuitive and user-friendly, enhancing user satisfaction and productivity.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Specialized Tools and Techniques"
                    image={toolsImage}
                    alt="Specialized Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We employ state-of-the-art testing tools and methodologies tailored to ERP and CRM systems:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated testing tools to speed up the testing cycles and increase coverage.</ListItem>
                        <ListItem icon={faCheckCircle}>Custom scripts and testing frameworks developed specifically for ERP and CRM functionalities.</ListItem>
                        <ListItem icon={faCheckCircle}>Advanced monitoring tools to track performance and user interaction in real-time.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of ERP and CRM Testing"
                    image={benefitsImage}
                    alt="Benefits of ERP and CRM Testing"
                >
                    <p className="text-lg mb-6">Implementing thorough testing of your ERP and CRM systems provides significant advantages:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Enhanced Reliability">
                            Minimize system downtime and increase reliability through rigorous testing.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Improved Efficiency">
                            Streamline business operations by ensuring that ERP and CRM systems function optimally.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Reduced Costs">
                            Decrease maintenance costs by identifying and resolving issues before they escalate.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Opt for Firebucks for your ERP and CRM Product Testing needs and benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Deep Expertise">
                            Our testers have extensive experience in the specific challenges and requirements of testing ERP and CRM systems.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Testing Approaches">
                            We tailor our testing services to fit the unique needs and complexities of your specific ERP and CRM implementations.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            We ensure that every aspect of your system's functionality, integration, performance, and security is thoroughly tested.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our ERP and CRM Product Testing services and how we can help ensure your systems are efficient, secure, and fully operational.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default ERPandCRMProductTesting;