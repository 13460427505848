import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import selfHealingImage from './assets/self-healing-scripts.png'; // Make sure to add this image to your assets folder

const SelfHealingScripts = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-6xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-green-500 via-blue-500 to-purple-500">
                        Self-Healing Scripts
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Optimize your testing processes with Firebucks' Self-Healing Scripts service. Our advanced AI-driven scripts automatically adapt to changes in your application, minimizing the need for manual intervention and ensuring continuous, reliable testing.
                    </p>
                </header>

                <section className="flex flex-col md:flex-row items-center mb-32">
                    <div className="w-full md:w-1/2 mb-8 md:mb-0">
                        <img src={selfHealingImage} alt="Self-Healing Scripts" className="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-6">Key Features</h2>
                        <ul className="space-y-4">
                            <ListItem icon={faCheckCircle} title="AI-Driven Adaptation">
                                Our scripts employ machine learning algorithms to monitor and understand the behavior of your application, adjusting to changes automatically.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Dynamic Locators">
                                We use intelligent element identification and pattern recognition to maintain script effectiveness as your application evolves.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="CI/CD Integration">
                                Seamlessly integrate our self-healing scripts into your CI/CD pipelines for real-time adaptation and reduced downtime.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Comprehensive Logging">
                                Gain insights into script adjustments with our detailed logging and reporting features.
                            </ListItem>
                        </ul>
                    </div>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">Additional Benefits</h2>
                    <p className="text-lg text-gray-600 mb-4">
                        Our self-healing scripts also include comprehensive logging and reporting features. These provide insights into how and why scripts were adjusted, offering transparency and enabling continuous improvement of your testing processes.
                    </p>
                    <p className="text-lg text-gray-600 mb-4">
                        Security and performance are integral to our self-healing scripts. We incorporate best practices for secure coding and optimize the scripts to run efficiently, minimizing their impact on the overall testing cycle.
                    </p>
                </section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to leverage the power of self-healing scripts. Our expertise ensures that your testing framework is adaptive, resilient, and capable of keeping up with the pace of modern software development, allowing your team to focus on innovation and quality.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-green-500 via-blue-500 to-purple-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Contact Us Today
                    </button>
                </div>
            </div>
        </div>
    );
};

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-blue-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            <strong className="text-lg">{title}:</strong> {children}
        </div>
    </li>
);

export default SelfHealingScripts;