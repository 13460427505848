import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import agile from './assets/agile.png';
import cci from './assets/ci.png';
import devops from './assets/devops.png';

const services = [
    {
        title: 'Agile Transformation',
        description: 'We help organizations transition to agile methodologies, improving flexibility and responsiveness to changing market demands.',
        icon: faArrowCircleRight,
        link: '/agile-transformation',
        image: agile
    },
    {
        title: 'Continuous Integration',
        description: 'Our continuous integration services ensure that code changes are automatically tested and integrated, reducing integration issues and accelerating delivery.',
        icon: faArrowCircleRight,
        link: '/continous-integration',
        image: cci
    },
    {
        title: 'DevOps Practices',
        description: 'Implementing DevOps practices helps streamline the development and operations process, enhancing collaboration and efficiency.',
        icon: faArrowCircleRight,
        link: '/devops-practices',
        image: devops
    }
];

const DeliveryTransformation = () => {
    return (
        <div className="container mx-auto px-4 py-8 sm:py-16 sm:mt-4">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold text-center pb-4 mb-6 mt-16 sm:mt-0 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                Delivery Transformation
            </h1>
            
            <p className="text-center mb-8 sm:mb-16 text-base sm:text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
                At Firebucks, our delivery transformation services are designed to streamline your processes, reduce turnaround time, and enhance product quality. We leverage cutting-edge technologies and methodologies to ensure your projects are delivered on time and within budget.
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-12 sm:mb-20">
                {services.map((service, index) => (
                    <div key={index} className="relative group overflow-hidden rounded-xl shadow-lg">
                        <img 
                            src={service.image}
                            alt={service.title} 
                            className="w-full h-48 sm:h-64 object-cover transition-transform duration-300 transform group-hover:scale-110"
                        />
                        <div className="absolute inset-x-0 top-0 bg-black bg-opacity-50 p-3 sm:p-4">
                            <h3 className="text-xl sm:text-2xl font-bold text-white tracking-wide drop-shadow-lg">
                                {service.title}
                            </h3>
                        </div>
                        <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center p-4 sm:p-6">
                            <p className="text-white text-center text-sm sm:text-base">{service.description}</p>
                        </div>
                        <button className="absolute bottom-2 right-2 sm:bottom-4 sm:right-4 bg-white rounded-full p-2 shadow-lg" onClick={() => {
                            window.location.href = service.link;
                        }}>
                            <FontAwesomeIcon icon={service.icon} className="text-blue-500 text-sm sm:text-base" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeliveryTransformation;