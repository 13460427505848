import React, { useState } from 'react';

const ContactButton = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="flex flex-col items-center justify-center py-16 px-4">
            <p className="text-2xl text-gray-700 text-center max-w-2xl mb-8">
                Ready to elevate your software quality? Get in touch with Firebucks for comprehensive testing solutions.
            </p>
            
            <button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => window.location.href = '/contact-us'}
                className={`
                    relative overflow-hidden
                    w-40 h-40
                    rounded-full
                    border-2 border-[#ce1352]
                    text-xl font-semibold
                    transition-transform duration-300
                    ${isHovered ? 'scale-110' : 'scale-100'}
                `}
            >
                {/* Button background fill effect */}
                <div
                    className={`
                        absolute inset-0
                        bg-[#ce1352]
                        transition-transform duration-500 ease-out
                        ${isHovered ? 'translate-y-0' : 'translate-y-full'}
                    `}
                />
                
                {/* Button text */}
                <span className={`
                    relative z-10
                    transition-colors duration-300
                    ${isHovered ? 'text-white' : 'text-[#ce1352]'}
                `}>
                    Contact Us
                </span>
            </button>
        </div>
    );
};

export default ContactButton; 