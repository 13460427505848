import React from 'react'; 
import continuousMonitoringImage from './assets/hero_erwin_wurm_mobiile.png';
import biasDetectionImage from './assets/model-monitoring.png';
import validationImage from './assets/holistic.png';

const ModelMonitoringValidation = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Model Monitoring and Validation
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure the accuracy and fairness of your AI models with Firebucks' Model Monitoring and Validation services. Our comprehensive approach involves continuous monitoring and validation, addressing critical issues such as bias and model drift to maintain the reliability and integrity of your AI systems.
                    </p>
                </header>

                <Section
                    title="Continuous Monitoring"
                    image={continuousMonitoringImage}
                    alt="Continuous Monitoring"
                >
                    <p className="text-lg mb-6">Our continuous monitoring services track the performance of your AI models in real-time. We use advanced analytics to detect any deviations or anomalies, ensuring that your models perform as expected in production environments. This proactive approach allows for early detection of issues, minimizing the impact on your operations.</p>
                    <p className="text-lg mb-6">Model drift can significantly impact the performance of AI systems over time. We monitor for both data drift and concept drift, ensuring that your models remain accurate and relevant. By regularly retraining models with new data, we ensure that they adapt to changing conditions and maintain their predictive power.</p>
                </Section>

                <Section
                    title="Bias Detection and Mitigation"
                    image={biasDetectionImage}
                    alt="Bias Detection and Mitigation"
                    reverse
                >
                    <p className="text-lg mb-6">Ensuring fairness in AI models is crucial. Our bias detection and mitigation services identify and address potential biases in your models, promoting ethical AI practices. We implement fairness algorithms and conduct thorough audits to ensure that your models make unbiased decisions, complying with regulatory standards and ethical guidelines.</p>
                </Section>

                <Section
                    title="Validation and Compliance"
                    image={validationImage}
                    alt="Validation and Compliance"
                >
                    <p className="text-lg mb-6">Our model validation services include rigorous testing and evaluation to ensure compliance with industry standards and regulations. We use techniques such as cross-validation, A/B testing, and performance benchmarking to validate model accuracy and robustness. This ensures that your AI systems are reliable and trustworthy.</p>
                    <p className="text-lg mb-6">We provide detailed reports and dashboards that offer insights into model performance, bias metrics, and drift indicators. These tools enable you to make informed decisions and take corrective actions when necessary, ensuring the long-term success of your AI initiatives.</p>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to leverage our Model Monitoring and Validation services. Our expertise ensures that your AI models are accurate, fair, and compliant, driving confidence in your AI-driven decisions.
                    </p>
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Model Monitoring and Validation services and how we can help you maintain the integrity of your AI systems.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

export default ModelMonitoringValidation;