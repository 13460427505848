// digital_assurance.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBug, faRobot, faTachometerAlt, faServer} from '@fortawesome/free-solid-svg-icons'; 
import SpecializedTesting from './assets/specialized_testing_card.png'
import CoreTesting from './assets/core_testing_card.png'
import AdvancedAssurance from './assets/advance_assurance_card.png'
import Gold from './assets/gold_block.png'
import Background from './assets/digital_assurance_background.png'
import ContactButton from './ContactButton';

const DigitalAssurance = () => {
    return (
        <div className="mx-auto bg-white min-h-screen">
            <header className="relative bg-cover bg-center text-white py-28 px-6" style={{ backgroundImage: `url(${Background})`,
            backgroundPosition: 'bottom',
            backgroundSize: '100% 100%'
             }}>
                <div className="relative">
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">Quality Assurance</h1>
                </div>
            </header>
            
            <section className="container mx-auto content bg-white p-6 rounded-lg shadow-lg mt-8 px-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <a href="/services/specialized-testing" className="rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105 text-gray-700" style={{ backgroundImage: `url(${Gold})` }}>
                        <img src={SpecializedTesting} alt="Specialized Testing" className="w-full h-32 object-cover rounded-md mb-4" />
                        <h4 className="text-xl font-semibold mb-2">GenAI Assurance</h4>
                        <p className="text-sm">Explore our specialized testing services designed to meet unique industry needs.</p>
                    </a>
                    <a href="/services/core-testing" className="rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105 text-gray-700" style={{ backgroundImage: `url(${Gold})` }}>
                        <img src={CoreTesting} alt="Core Testing" className="w-full h-32 object-cover rounded-md mb-4" />
                        <h4 className="text-xl font-semibold mb-2">Essential Assurance</h4>
                        <p className="text-sm">Discover our core testing solutions that ensure quality and reliability.</p>
                    </a>
                    <a href="/services/advanced-assurance" className="rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105 text-gray-700" style={{ backgroundImage: `url(${Gold})` }}>
                        <img src={AdvancedAssurance} alt="Advanced Assurance" className="w-full h-32 object-cover rounded-md mb-4" />
                        <h4 className="text-xl font-semibold mb-2">Advanced Assurance</h4>
                        <p className="text-sm">Learn about our advanced assurance services that drive innovation and efficiency.</p>
                    </a>
                </div>
                <br/><br/> 
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                    <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105">
                        <FontAwesomeIcon icon={faClock} className="text-4xl text-[#0f0f0f] mb-4" />
                        <h4 className="text-xl font-semibold mb-2">Reduced Testing Time</h4>
                        <p className="text-gray-700">Testing cycle time reduced by 40%</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105">
                        <FontAwesomeIcon icon={faBug} className="text-4xl text-[#0f0f0f] mb-4" />
                        <h4 className="text-xl font-semibold mb-2">Improved Defect Detection</h4>
                        <p className="text-gray-700">Defect detection rate improved by 30%</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105">
                        <FontAwesomeIcon icon={faRobot} className="text-4xl text-[#0f0f0f] mb-4" />
                        <h4 className="text-xl font-semibold mb-2">Automated Regression</h4>
                        <p className="text-gray-700">70% of regression test cases automated</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105">
                        <FontAwesomeIcon icon={faTachometerAlt} className="text-4xl text-[#0f0f0f] mb-4" />
                        <h4 className="text-xl font-semibold mb-2">Performance Boost</h4>
                        <p className="text-gray-700">Application performance enhanced by 25%</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:scale-105">
                        <FontAwesomeIcon icon={faServer} className="text-4xl text-[#0f0f0f] mb-4" />
                        <h4 className="text-xl font-semibold mb-2">High Uptime</h4>
                        <p className="text-gray-700">99.9% uptime achieved during critical releases</p>
                    </div>
                </div>
            </section>

            <ContactButton />
        </div>
    );
};

export default DigitalAssurance;
