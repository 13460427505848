import React from 'react';
import specializedEngineeringBackground from  './assets/specialized_engineering_background.png';
import specializedEngineering from './assets/specialized_engineering_header.png';

import embeddedSystemsImage from './assets/embedded_systems_image.png';
import iotSolutionsImage from './assets/iot_solutions_image.png';
import aiMachineLearningImage from './assets/ai_machine_learning_image.png';
import ContactButton from './ContactButton';

const SpecializedEngineering = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 pb-8 pt-0 sm:px-6 lg:px-8">
                <header className="relative bg-cover bg-no-repeat bg-center text-white lg:h-72 flex flex-col justify-center items-center" style={{ backgroundImage: `url(${specializedEngineeringBackground})`, backgroundPosition: 'center' }}>
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Specialized Engineering Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto pt-8">
                        Leverage our specialized engineering expertise to enhance your product development.
                    </p>
                </header>
<br/>
                <Section
                    title="Innovative Engineering Solutions"
                    image={specializedEngineering}
                    alt="Specialized Engineering"
                    className="w-full h-auto rounded-lg flex justify-center text-left items-center"
                >
                    <p className="text-lg text-left mb-6">
                        Our Specialized Engineering services include a range of solutions designed to meet your unique needs and drive innovation. We combine cutting-edge technologies with proven methodologies to deliver exceptional results. Our team of expert engineers works closely with you to understand your requirements and create customized solutions that exceed expectations.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Engineering Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={embeddedSystemsImage}
                                title="Embedded Systems"
                                description="Design and development of embedded systems tailored to your specific requirements."
                            />
                            <Card
                                image={iotSolutionsImage}
                                title="IoT Solutions"
                                description="Comprehensive IoT solutions to connect and manage your devices efficiently."
                            />
                            <Card
                                image={aiMachineLearningImage}
                                title="AI and Machine Learning"
                                description="Advanced AI and machine learning solutions to enhance your business intelligence."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full h-1/2 md:w-1/2 flex justify-center items-center mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-2/3 h-1/2 rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8 text-left">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default SpecializedEngineering; 