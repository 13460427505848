import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faDollarSign, faFilm, faShoppingCart, faBook, faGamepad, faPlane } from '@fortawesome/free-solid-svg-icons';
import './industry.css';
function IndustryAccordion() {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? index: -1);
  };

  const getIconColor = (index) => (index === activeIndex ? 'blue' : 'black');

  return ( 
    <div className="industry-accordion">
    <h3 style={{textAlign: 'center',color:'#0f0f0f',fontSize:'2.5em',paddingTop:'1em', paddingBottom:'1em'}}> Unleashing Potential Across Industry Verticals</h3>
    <div style={{ backgroundImage: `url(${require('./assets/industry_bg.png')})`,backgroundSize: '100% 100%',backgroundPosition: 'bottom' }}>
      <Accordion 
        allowZeroExpanded 
        className="opacity-100"
      >
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(0)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faStethoscope} color={getIconColor(0)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">Healthcare</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              In the Healthcare sector, Firebucks has significantly improved patient care through innovative technology solutions. Our focus on value-based care has revolutionized healthcare delivery, aligning provider payments with patient outcomes. By implementing advanced analytics and care coordination platforms, we've helped healthcare organizations transition from fee-for-service to value-based models, resulting in improved quality of care and reduced costs.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(1)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faDollarSign} color={getIconColor(1)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">FinTech</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              Firebucks leverages cutting-edge technology to transform the FinTech industry, with a focus on banking and investment solutions. Our innovative platforms have revolutionized digital banking, providing seamless and secure transactions for millions of users worldwide. In the investment sector, we've developed AI-driven analytics tools that empower financial advisors and individual investors to make data-informed decisions, ultimately leading to improved portfolio performance and risk management.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(2)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faFilm} color={getIconColor(2)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">Media and Entertainment</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              In the Media and Entertainment industry, Firebucks has enabled companies to deliver immersive and engaging content...
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(3)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faShoppingCart} color={getIconColor(3)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">Retail</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              Firebucks' expertise in the Retail industry focuses on enhancing customer experiences and optimizing supply chain management. Our e-commerce solutions have revolutionized online shopping, providing seamless, personalized experiences that drive conversion rates and customer loyalty. In automotive retail, we've developed innovative digital showrooms and virtual test drive platforms, transforming the car buying process and helping dealerships adapt to changing consumer preferences in the digital age.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(4)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faBook} color={getIconColor(4)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">eLearning</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              In the eLearning sector, Firebucks provides innovative solutions that enhance the learning experience and improve educational outcomes. Our adaptive learning platforms use AI to personalize content delivery, while our virtual classroom technologies enable seamless remote education, fostering engagement and knowledge retention for students of all ages.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(5)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faGamepad} color={getIconColor(5)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">Gaming</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              Firebucks' work in the Gaming industry is highlighted by our comprehensive game testing and quality assurance services. Our expert testers ensure seamless gameplay experiences across multiple platforms and devices, identifying and resolving issues before they reach end-users. We provide functional testing, localization testing, and compliance checks, helping game developers deliver high-quality, bug-free products that meet global standards and player expectations.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton 
              onClick={() => handleAccordionClick(6)} 
              className="flex items-center p-4 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faPlane} color={getIconColor(6)} className="text-xl" />
              <span className="ml-4 text-lg font-medium">Travel, Tourism & Hospitality</span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white">
            <p className="text-base leading-relaxed text-gray-700 m-0 p-5 text-justify">
              In the Travel, Tourism & Hospitality sector, Firebucks has enabled companies to enhance their service delivery and customer engagement. Our innovative solutions focus on improving user experiences through personalized recommendations and seamless booking processes. We also prioritize data protection, implementing robust security measures to safeguard sensitive customer information and ensure compliance with global privacy regulations.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      </div>
    </div>
    
  );
}

export default IndustryAccordion;