import React from 'react'; 
import strategicAssessmentsImage from './assets/strategic_assessments.png'; // Ensure this image exists
import strategicAssessmentsBackground from './assets/strategic_assesment_background.png'; // Add this line
import marketAnalysisImage from './assets/market_analysis_image.png'; // Add this line
import riskAssessmentImage from './assets/risk_assessment_image.png'; // Add this line
import competitiveBenchmarkingImage from './assets/competitive_benchmarking_image.png'; // Add this line
import ContactButton from './ContactButton';

const StrategicAssessments = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <header 
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ backgroundImage: `url(${strategicAssessmentsBackground})`, backgroundPosition: 'center' }}
                >
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Strategic Assessments
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Gain insights and drive strategic decisions with our comprehensive assessments.
                    </p>
                </header>

                <Section
                    title="In-Depth Analysis"
                    image={strategicAssessmentsImage}
                    alt="Strategic Assessments"
                >
                    <p className="text-lg text-left mb-6">
                        Our Strategic Assessments services provide a comprehensive analysis of market trends, risk factors, and competitive landscapes. We deliver actionable insights to help you make informed strategic decisions.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Assessment Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={marketAnalysisImage}
                                title="Market Analysis"
                                description="Comprehensive analysis of market trends and dynamics."
                            />
                            <Card
                                image={riskAssessmentImage}
                                title="Risk Assessment"
                                description="Identify and evaluate potential risks to your business."
                            />
                            <Card
                                image={competitiveBenchmarkingImage}
                                title="Competitive Benchmarking"
                                description="Analyze and compare your position against competitors."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);
 

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default StrategicAssessments; 