import React, { useEffect, useState } from "react";
import C2 from './assets/ai_back.png';
const ThirdHeroAI = () => {
  const [articles, setArticles] = useState([]);

  const fetchNewsFromNewsAPI = async () => {
    try {
      const response = await fetch("https://eventregistry.org/api/v1/article/getArticles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "getArticles",
          keyword: "AI,Healthcare",
          sourceLocationUri: [
            "http://en.wikipedia.org/wiki/United_States",
            "http://en.wikipedia.org/wiki/India",
            "http://en.wikipedia.org/wiki/United_Kingdom",
          ],
          ignoreSourceGroupUri: "paywall/paywalled_sources",
          articlesPage: 1,
          articlesCount: 3,
          articlesSortBy: "date",
          articlesSortByAsc: false,
          dataType: ["news"],
          forceMaxDataTimeWindow: 31,
          resultType: "articles",
          apiKey: "0007fea6-8593-4426-82ec-9974c23a3a64",
        }),
      });

      const data = await response.json();
      if (data.articles && data.articles.results) {
        return data.articles.results.map(article => ({
          title: article.title,
          description: article.body ? article.body.slice(0, 100) + "..." : "No description available.",
          url: article.url,
          image: article.image,
        }));
      } else {
        throw new Error("Failed to fetch news from NewsAPI");
      }
    } catch (error) {
      console.error("Error fetching news from NewsAPI.ai:", error);
      return null;
    }
  };

  const fetchNewsFromSauravAPI = async () => {
    try {
      const response = await fetch("https://saurav.tech/NewsAPI/top-headlines/category/technology/us.json");
      const data = await response.json();
      if (data.articles) {
        return data.articles.map(article => ({
          title: article.title,
          description: article.description ? article.description.slice(0, 100) + "..." : "No description available.",
          url: article.url,
          image: article.urlToImage,
        }));
      } else {
        throw new Error("Failed to fetch news from Saurav API");
      }
    } catch (error) {
      console.error("Error fetching news from Saurav API:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let news = await fetchNewsFromNewsAPI();
      if (!news) {
        news = await fetchNewsFromSauravAPI();
      }
      setArticles(news.filter(article => article.title && article.description).slice(0, 3));
    };

    fetchData();
  }, []);

  return (
    <div className="bg-cover bg-center min-h-screen p-10 flex flex-col justify-center items-center" style={{ backgroundImage: `url(${C2})` }}>
      <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mt-8 mb-4 text-center">Whats new in tech</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 flex-grow justify-items-center">
        {articles.length > 0 ? (
          articles.map((article, index) => (
            <div
              key={index}
              className="bg-white bg-opacity-60 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow flex flex-col justify-center"
            >
              <h2 className="text-xl font-semibold mb-4 text-center">{article.title}</h2>
              <p className="text-sm">{article.description}</p>
              <a href={article.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                Read More
              </a>
            </div>
          ))
        ) : (
          <p>Loading news articles...</p>
        )}
      </div>
    </div>
  );
};

export default ThirdHeroAI;
