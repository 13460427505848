import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import toolsImage from './assets/proven.png';
import benefitsImage from './assets/additional.png';
import chooseUsImage from './assets/choose_us.png';
import { Link } from 'react-router-dom';

const AgileTransformation = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        <Link to="/services/agile-transformation-services">Agile Transformation Services</Link>
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Accelerate your organization's transition to Agile methodologies with Firebucks' Agile Transformation services. Our expert team helps businesses adopt Agile practices to enhance productivity, improve product quality, and increase customer satisfaction.
                    </p>
                </header>

                <Section
                    title="Comprehensive Agile Adoption Strategy"
                    image={holisticImage}
                    alt="Comprehensive Agile Adoption Strategy"
                >
                    <p className="text-lg mb-6">Our Agile Transformation services include a holistic approach to adopting Agile:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Agile Readiness Assessment">
                            Evaluating your organization's readiness for Agile adoption and identifying key areas for change.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Training Programs">
                            Delivering tailored training sessions to teams and leadership to ensure understanding and implementation of Agile principles.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Process Re-engineering">
                            Redesigning existing processes to align with Agile methodologies, such as Scrum, Kanban, or Lean.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Coaching and Mentoring">
                            Providing ongoing support to ensure sustainable Agile practices and help resolve any challenges along the way.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Tools and Techniques"
                    image={toolsImage}
                    alt="Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We leverage modern tools and techniques to facilitate a smooth Agile transformation:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Agile project management tools to enhance team collaboration and workflow transparency.</ListItem>
                        <ListItem icon={faCheckCircle}>Metrics and KPIs to measure Agile adoption success and continuous improvement.</ListItem>
                        <ListItem icon={faCheckCircle}>Techniques for fostering a collaborative and adaptive team environment.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of Agile Transformation"
                    image={benefitsImage}
                    alt="Benefits of Agile Transformation"
                >
                    <p className="text-lg mb-6">Adopting Agile methodologies brings numerous benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Increased Flexibility">
                            Rapidly adapt to market changes and customer feedback.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Efficiency">
                            Streamline processes and reduce time-to-market.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Improved Stakeholder Engagement">
                            Closer collaboration with stakeholders and continuous delivery of value.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Agile Transformation to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Proven Expertise">
                            Our team has extensive experience in guiding organizations through successful Agile transformations.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Solutions">
                            We tailor our approach to fit your organization's specific needs and challenges.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Support">
                            From initial assessment to full implementation and beyond, we provide continuous support to ensure your transformation is effective.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Agile Transformation services and how we can help your organization become more adaptive, efficient, and competitive in today's fast-paced market.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default AgileTransformation;