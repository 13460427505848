import React from 'react'; 
import coreTestingImage from './assets/core_testing_heading.png'; // Ensure this image exists
import coreTestingBackground from './assets/core_testing_background.png';

// Import images for the cards
import functionalTestingImage from './assets/functional_testing_image.png';
import regressionTestingImage from './assets/regression_testing_image.png';
import integrationTestingImage from './assets/integration_testing_image.png';
import performanceTestingImage from './assets/performance_testing_image.png';
import securityTestingImage from './assets/security_testing_image.png';
import userAcceptanceTestingImage from './assets/user_accepted_testing_image.png';

import ContactButton from './ContactButton';

const CoreTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="m mx-auto px-4 sm:px-6 lg:px-8">
                <header 
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ backgroundImage: `url(${coreTestingBackground})`, backgroundPosition: 'center' }}
                >
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                       Essential Assurance Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure the quality and reliability of your software with our comprehensive services.
                    </p>
                </header>

                <Section
                    title="Comprehensive Testing Approach"
                    image={coreTestingImage}
                    alt="Core Testing"
                >
                    <p className="text-lg mb-6">
                        At Firebucks, we offer a professional and thorough approach to software testing. Our services are designed to ensure your software is robust and reliable, helping you reduce costs and improve time-to-market.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Testing Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={functionalTestingImage}
                                title="Functional Testing"
                                description="Ensures that the software operates according to the required specifications."
                            />
                            <Card
                                image={regressionTestingImage}
                                title="Regression Testing"
                                description="Verifies that new code changes do not adversely affect existing functionalities."
                            />
                            <Card
                                image={integrationTestingImage}
                                title="Integration Testing"
                                description="Tests the interfaces between components and interactions with different parts of the system."
                            />
                            <Card
                                image={performanceTestingImage}
                                title="Performance Testing"
                                description="Assesses the speed, scalability, and stability characteristics of the system."
                            />
                            <Card
                                image={securityTestingImage}
                                title="Security Testing"
                                description="Identifies vulnerabilities and ensures the software is secure against threats."
                            />
                            <Card
                                image={userAcceptanceTestingImage}
                                title="User Acceptance Testing"
                                description="Validates the end-to-end business flow and ensures the system meets business needs."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden lower section">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default CoreTesting; 