import React from 'react'; 
import staffingConsultingImage from './assets/staffing_consulting.png'; // Ensure this image exists
import staffingConsultingBackground from './assets/staffing_and_consulting_background.png';
import ContactButton from './ContactButton';
import itStaffingImage from './assets/it_staffing_image.png';
import projectManagementImage from './assets/project_management_image.png';
import businessConsultingImage from './assets/business_consulting_image.png';
const StaffingConsulting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 pb-8 pt-0 sm:px-6 lg:px-8">
                <header className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center" style={{ backgroundImage: `url(${staffingConsultingBackground})`, backgroundPosition: 'center' }}>
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Staffing & Consulting Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance your team with our expert staffing and consulting services.
                    </p>
                </header>
                <br/><br/>
                <Section 
                    title="Tailored Staffing Solutions"
                    image={staffingConsultingImage}
                    alt="Staffing & Consulting"
                >
                    <p className="text-lg text-left mb-6">
                        Our Staffing & Consulting services provide comprehensive solutions to meet your business needs. We offer expert IT staffing, project management, and business consulting services to help you achieve your goals.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Consulting Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={itStaffingImage}
                                title="IT Staffing"
                                description="Expert IT staffing solutions tailored to your specific requirements."
                            />
                            <Card
                                image={projectManagementImage}
                                title="Project Management"
                                description="Comprehensive project management services to ensure successful project delivery."
                            />
                            <Card
                                image={businessConsultingImage}
                                title="Business Consulting"
                                description="Strategic business consulting to drive growth and efficiency."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

 

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default StaffingConsulting; 