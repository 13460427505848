import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import comprehensiveImage from './assets/comprehensive.png'
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const MobileTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Mobile Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure the highest quality, functionality, and performance of your mobile applications with Firebucks' Mobile Testing services. Our team provides comprehensive testing solutions tailored to the unique challenges of mobile platforms.
                    </p>
                </header>

                <Section
                    title="Comprehensive Mobile Testing Approach"
                    image={comprehensiveImage}
                    alt="Comprehensive Mobile Testing Approach"
                >
                    <p className="text-lg mb-6">Our Mobile Testing services include a wide range of testing types:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Functional Testing">
                            Verifying that all features function according to the specified requirements.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Usability Testing">
                            Ensuring the app is easy to use and provides a satisfying user experience.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Testing">
                            Testing the app's performance, including its responsiveness, stability under load, and battery consumption.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Testing">
                            Identifying vulnerabilities to ensure data privacy and protection against threats.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Compatibility Testing">
                            Ensuring the app works seamlessly across various devices, OS versions, and screen sizes.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Technologies"
                    image={toolsImage}
                    alt="Advanced Tools and Technologies"
                    reverse
                >
                    <p className="text-lg mb-6">We utilize state-of-the-art tools and technologies for effective mobile testing:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated testing tools like Appium and Xamarin.UITest</ListItem>
                        <ListItem icon={faCheckCircle}>Cloud-based device labs for testing across a wide range of devices</ListItem>
                        <ListItem icon={faCheckCircle}>Performance monitoring tools to assess resource usage and response times</ListItem>
                        <ListItem icon={faCheckCircle}>Security penetration testing tools to evaluate the robustness of mobile security</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Mobile Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            A team of experienced mobile testing professionals knowledgeable in cutting-edge testing technologies.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Custom Solutions">
                            Tailored testing strategies that align with your mobile application requirements.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Quality Assurance">
                            Comprehensive testing that covers every aspect of the mobile app, ensuring a seamless user experience.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Proven Results">
                            A track record of improving mobile app performance, security, and market readiness.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Mobile Testing services and how we can help ensure the success of your mobile application.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default MobileTesting;