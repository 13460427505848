import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const TestAutomation = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <header className="mb-16 text-center">
          <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
            Test Automation Services
          </h1>
          <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
            At Firebucks, we specialize in Test Automation services that streamline your software testing processes, ensuring faster releases and higher quality applications. Our automation frameworks and tools are designed to improve efficiency, accuracy, and coverage in testing.
          </p>
        </header>

        <Section
          title="Comprehensive Automation Strategy"
          image={holisticImage}
          alt="Comprehensive Automation Strategy"
        >
          <p className="text-lg mb-6">Our Test Automation services include:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle} title="Automation Framework Design">
              Creating robust and scalable automation frameworks tailored to your specific needs.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Script Development">
              Developing high-quality test scripts that cover a wide range of test cases and scenarios.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Tool Integration">
              Integrating leading automation tools and platforms to ensure seamless execution of automated tests.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Maintenance and Updates">
              Regularly updating and maintaining test scripts to adapt to changes in the application.
            </ListItem>
          </ul>
        </Section>

        <Section
          title="Proven Expertise and Tools"
          image={toolsImage}
          alt="Proven Expertise and Tools"
          reverse
        >
          <p className="text-lg mb-6">Our team utilizes the latest tools and technologies to enhance the effectiveness of our Test Automation services:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle}>Selenium, Appium, and Cypress for web and mobile application testing</ListItem>
            <ListItem icon={faCheckCircle}>Jenkins for Continuous Integration/Continuous Deployment (CI/CD)</ListItem>
            <ListItem icon={faCheckCircle}>TestNG and JUnit for test management and reporting</ListItem>
            <ListItem icon={faCheckCircle}>Advanced scripting languages like Python, Java, and JavaScript</ListItem>
          </ul>
        </Section>

        <Section
          title="Additional Automation Services"
          image={industryImage}
          alt="Additional Automation Services"
        >
          <p className="text-lg mb-6">We also offer a range of additional automation services to further enhance your testing processes:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle} title="Performance Testing Automation">
              Automating performance tests to ensure your applications can handle high loads and stress conditions.
            </ListItem>
            <ListItem icon={faCheckCircle} title="API Testing Automation">
              Automating API tests to verify the functionality, reliability, and security of your application's APIs.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Regression Testing Automation">
              Automating regression tests to quickly identify any issues introduced by new code changes.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Data-Driven Testing">
              Implementing data-driven testing techniques to run tests with multiple sets of data inputs for comprehensive coverage.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Behavior-Driven Development (BDD)">
              Using BDD frameworks like Cucumber to create tests that are easy to understand and maintain.
            </ListItem>
          </ul>
        </Section>

        <Section
          title="Why Choose Firebucks?"
          image={chooseUsImage}
          alt="Why Choose Firebucks"
          reverse
        >
          <p className="text-lg mb-6">Partner with Firebucks for your Test Automation needs to benefit from:</p>
          <ul className="space-y-4">
            <ListItem icon={faCheckCircle} title="Expert Team">
              A team of experienced professionals skilled in the latest automation tools and techniques.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Custom Solutions">
              Tailored automation frameworks that meet your specific testing requirements.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Enhanced Efficiency">
              Increased testing efficiency and reduced time-to-market through automation.
            </ListItem>
            <ListItem icon={faCheckCircle} title="Quality Assurance">
              Improved application quality through thorough and consistent automated testing.
            </ListItem>
          </ul>
        </Section>

        <div className="mt-16 text-center">
          <p className="text-xl text-gray-700 mb-8">
            Contact us today to learn more about our Test Automation services and how we can help you achieve your quality assurance goals.
          </p>
          <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children, image, alt, reverse }) => (
  <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
    <div className="w-full md:w-1/2 mb-8 md:mb-0">
      <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
    </div>
    <div className="w-full md:w-1/2 md:px-8">
      <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
      {children}
    </div>
  </section>
);

const ListItem = ({ icon, title, children }) => (
  <li className="flex items-start">
    <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
    <div className="text-left">
      {title && <strong className="text-lg">{title}:</strong>} {children}
    </div>
  </li>
);

export default TestAutomation;