import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import comprehensiveImage from './assets/comprehensive.png';
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const CompatibilityTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Compatibility Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure your application performs seamlessly across various devices, browsers, and operating systems with Firebucks' comprehensive Compatibility Testing services. Our team ensures your product delivers a consistent user experience, regardless of the platform.
                    </p>
                </header>

                <Section
                    title="Comprehensive Coverage"
                    image={comprehensiveImage}
                    alt="Comprehensive Coverage"
                >
                    <p className="text-lg mb-6">Our Compatibility Testing services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Cross-Browser Testing">
                            Ensuring functionality and design consistency across different web browsers.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Cross-Platform Testing">
                            Verifying performance on various operating systems, including Windows, macOS, Linux, Android, and iOS.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Device Compatibility Testing">
                            Testing on a wide range of devices to ensure optimal performance on desktops, tablets, and smartphones.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Resolution Testing">
                            Checking application behavior on different screen resolutions and aspect ratios.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Techniques"
                    image={toolsImage}
                    alt="Advanced Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We use the latest tools and methodologies for thorough compatibility testing:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Selenium and Appium for automated cross-browser and cross-platform testing</ListItem>
                        <ListItem icon={faCheckCircle}>BrowserStack and Sauce Labs for cloud-based testing on real devices</ListItem>
                        <ListItem icon={faCheckCircle}>Manual testing on a diverse range of physical devices to cover all possible user scenarios</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Compatibility Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            Skilled testers proficient in the latest compatibility testing tools and techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            Extensive testing across various browsers, platforms, devices, and resolutions.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Consistent User Experience">
                            Ensuring your application performs optimally on all platforms, enhancing user satisfaction.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Detailed Reporting">
                            Providing actionable insights and recommendations for improving compatibility.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Compatibility Testing services and how we can help ensure your application performs flawlessly across all platforms.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default CompatibilityTesting;