// hero.js
import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import C1 from './assets/engineering.png'; 
import SecondHero from './second_hero' 
import WhyChooseUs from './why_us_second_hero.';
import ThirdHeroAI from './third_hero_ai';
import ContactUsHero from './contact_us_hero';
import bg from './assets/bg_option_2.png';
import C2 from './assets/quality_1980.png';
import C3 from './assets/delivery_product.png';
import C4 from './assets/innovation_1960.png';
const Hero = () => {
const words = useMemo(() => ['Build Smarter Apps with AI-Powered Precision.', 'Seamless Testing, Swift Turnarounds.', 'Deploy with Confidence, Scale with Ease.', 'Driving Innovation, Disrupting the Norm.'], []);
    const images = useMemo(() => [C1, C2, C3, C4], []);
    const imageContainerRef = useRef(null);
    const imageCubeRef = useRef(null);
    const textCubeRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [rotationAngle, setRotationAngle] = useState(0);

    const paragraphTexts = useMemo(() => [
        'From model training to real-time automation, we craft intelligent solutions tailored to your needs.',
        'Experience the power of AI-driven testing with self-healing scripts and automated insights.',
        'Our AI-enhanced DevOps and CI/CD solutions ensure seamless deployment every time.',
        'Harness the transformative power of AI to redefine possibilities and stay ahead of the curve.'
    ], []);

    const rotateCube = useCallback((angle) => {
        if (textCubeRef.current) {
            textCubeRef.current.style.transform = `rotateX(${angle}deg)`; // Rotate text cube vertically
        }
        if (imageCubeRef.current) {
            imageCubeRef.current.style.transform = `rotateY(${angle}deg)`; // Rotate image cube horizontally
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
            setRotationAngle((prevAngle) => {
                const newAngle = prevAngle + 90; // Increment angle by 90 degrees
                rotateCube(newAngle);
                return newAngle;
            });
        }, 10000); // Rotate every 12 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [rotateCube, words.length]);

 

    const isMobile = window.innerWidth <= 768; // Check if the device is mobile
  console.log(rotationAngle);
    return (
        <div className="relative">
            <div className="sticky top-0 left-0 w-full h-screen flex flex-col lg:flex-col items-center justify-between overflow-hidden px-4 sm:px-6 lg:px-8">
                <img
                    src={bg}
                    alt="Hero background"
                    className="absolute top-0 left-0 object-cover"
                    style={{ width: '100vw', height: '100vh', zIndex: -1 }}
                />
                <div className="text-center mt-[200px] sm:mt-[100px] lg:mt-[200px] lg:w-full" style={{ maxHeight: '30vh' }}>
                    <div className="scene" style={{ perspective: '100vw', width: '100%', maxWidth: '100%', height: '10vh' }}>
                        <div ref={textCubeRef} className="cube" style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            transformStyle: 'preserve-3d',
                            transition: 'transform 0.5s ease',
                        }}>
                            {words.map((word, index) => (
                                <div key={word} className={`face ${word}`} style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backfaceVisibility: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transform: `rotateX(${index * 90}deg) translateZ(150px)`,
                                }}>
                                    <span className="text-[#f16136] face sm:text-[10px] md:text-base lg:text-4xl font-mono sm:whitespace-nowrap whitespace-normal">
                                        {window.innerWidth <= 768 ? (
                                            word.split(' ').map((w, i, arr) => (
                                                <React.Fragment key={i}>
                                                    {w}
                                                    {i !== arr.length - 1 && (i + 1) % 2 === 0 ? <br/> : i !== arr.length - 1 ? ' ' : ''}
                                                </React.Fragment>
                                            ))
                                        ) : word}
                                    </span>
                                </div>
                            ))}
                                   </div>
                    </div>
                    <p 
                        className={`text-md sm:text-base lg:text-3xl w-full text-center`} 
                        style={{ marginTop: '1em', color: '#130f0f' }}
                    >
                        {paragraphTexts[currentIndex]}
                    </p>
                    <button 
                        onClick={() => { 
                            const nextSection = document.querySelector('.your-next-section-class'); // Update this selector
                            if (nextSection) {
                                const start = window.scrollY; // Use scrollY instead of pageYOffset
                                const end = nextSection.getBoundingClientRect().top + start - 70; // Adjusted to stop 70px from the top
                                const duration = 1000; // 1 second, which is a commonly recommended duration
                                const startTime = performance.now();

                                function easeInOutCubic(t) {
                                    return t < 0.5 
                                        ? 4 * t * t * t 
                                        : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
                                }

                                function animate(currentTime) {
                                    const elapsedTime = currentTime - startTime;
                                    const progress = Math.min(elapsedTime / duration, 1);
                                    const easeProgress = easeInOutCubic(progress);
                                    
                                    window.scrollTo(0, start + (end - start) * easeProgress);

                                    if (progress < 1) {
                                        requestAnimationFrame(animate);
                                    }
                                }

                                requestAnimationFrame(animate);
                            }
                        }}
                        className="mt-6 px-6 py-3 bg-white text-black font-semibold rounded-full shadow-lg hover:bg-red-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                        style={{ zIndex: 200, position: 'relative' }} // Add zIndex and position styles
                    >
                        Know More
                    </button>
                </div> 
                <div ref={imageContainerRef} className="hidden w-full lg:w-full flex-grow flex items-center justify-center my-8 lg:my-0">
                    <div className="scene sm:w-1/2 sm:h-1/2 md:w-1/2 md:h-1/2" style={{ perspective: '100vw', width: '100%', maxWidth: '80vw', height: '100%' }}>
                        <div ref={imageCubeRef} className="cube" style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            transformStyle: 'preserve-3d',
                            transition: 'transform 0.5s ease',
                        }}>
                            {images.map((image, index) => (
                                <div key={index} className={`face`} style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backfaceVisibility: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transform: `rotateY(${index * 90}deg) translateZ(150px)`,
                                }}>
                                    <img
                                        src={image}
                                        alt={`Hero ${index + 1}`}
                                        className="w-full h-full object-contain"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'contain',
                                            transform: isMobile ? 'scale(0.8)' : 'none', // Scale images to 60% on mobile
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative z-10">
                <WhyChooseUs />
                <SecondHero/> 
                <ThirdHeroAI/>
                <ContactUsHero/>
            </div>
        </div>
    );
}

export default Hero;
