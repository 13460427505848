// product.js
import React, { useState } from 'react';
import agileImage from './assets/deploy.png';
import techImage from './assets/footer_option1.jpg'
import qualityImage from './assets/office_background.png'
import designImage from  './assets/footer_option3.jpg'
import productManager from  './assets/hero ivan brunetti.png'
import MarketingExpert from './assets/industry_bg.jpg'
import ContactButton from './ContactButton';
import ProductEngineeringBackground from './assets/product_engineering_background.png';
const services = [
  {
    title: 'Developers',
    description: 'Seasoned software engineers, coders, and architects with expertise across hundreds of technologies.',
    icon: 'code',
    image: agileImage,
  },
  {
    title: 'Designers',
    description: 'Expert UI, UX, Visual, and Interaction designers, as well as a wide range of illustrators, animators, and more.',
    icon: 'pen-ruler',
    image: techImage,
  },
  {
    title: 'Finance Experts',
    description: 'Experts in financial modeling & valuation, startup funding, interim CFO work, and market sizing.',
    icon: 'chart-line',
    image: qualityImage,
  },
  {
    title: 'Project Managers',
    description: 'Digital and technical project managers, scrum masters, and more with expertise in numerous PM tools, frameworks, and styles.',
    icon: 'list-check',
    image: designImage,
  },
  {
    title: 'Product Managers',
    description: 'Digital product managers, scrum product owners with expertise in numerous industries like banking, healthcare, ecommerce, and more.',
    icon: 'box-open',
    image:  productManager,
  },
  {
    title: 'Marketing Experts',
    description: 'Experts in digital marketing, growth marketing, content creation, market research, brand strategy execution, social media marketing, and more.',
    icon: 'bullhorn',
    image: MarketingExpert,
  },
];

const accordionItems = [
  {
    title: 'Agile methodologies',
    content: 'Our agile approach ensures flexibility and rapid delivery of solutions, adapting to changing requirements and market conditions.',
    icon: 'fa-sync-alt'
  },
  {
    title: 'Tech expertise',
    content: 'We leverage cutting-edge technologies and industry best practices to deliver innovative and efficient solutions.',
    icon: 'fa-microchip'
  },
  {
    title: 'Quality assurance',
    content: 'Our rigorous quality assurance processes ensure that all deliverables meet the highest standards of performance and reliability.',
    icon: 'fa-check-circle'
  },
  {
    title: 'Design thinking',
    content: 'We employ design thinking principles to create user-centric solutions that address real-world problems effectively.',
    icon: 'fa-lightbulb'
  },
];

const Accordion = ({ title, content, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between items-center w-full py-5 px-4 text-left hover:bg-gray-50 transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center text-lg">
          <i className={`fas ${icon} mr-4 text-blue-500 text-xl`}></i>
          <span className="font-medium">{title}</span>
        </span>
        <span className="text-2xl font-light">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && <div className="py-4 px-4 bg-gray-50">{content}</div>}
    </div>
  );
};

const ServiceAccordion = () => {
  return (
    <div className="w-full mt-8 border-t border-gray-200 rounded-lg shadow-md">
      {accordionItems.map((item, index) => (
        <Accordion key={index} title={item.title} content={item.content} icon={item.icon} />
      ))}
    </div>
  );
};

const Product = () => {
  return (
    <div className="container mx-auto px-4 pb-16">
      <header 
        className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
        style={{ 
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${ProductEngineeringBackground})`,
          backgroundPosition: 'center'
        }}
      >
        <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
          Product Engineering
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Enhance digital transformation success with Virtusa. From creating solutions to global scaling, we support your goals in a dynamic environment.
        </p>
      </header>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
        {services.map((service, index) => (
          <div key={index} className="relative group overflow-hidden rounded-xl shadow-lg">
            <img 
              src={service.image}
              alt={service.title} 
              className="w-full h-64 object-cover transition-transform duration-300 transform group-hover:scale-110"
            />
            <div className="absolute inset-x-0 top-0 bg-black bg-opacity-50 p-4">
              <h3 className="text-2xl font-bold text-white tracking-wide drop-shadow-lg">
                {service.title}
              </h3>
            </div>
            <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center p-6">
              <p className="text-white text-center">{service.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="max-w-3xl mx-auto mt-20">
        <h3 className="text-3xl font-bold mb-6 text-gray-800">Why choose us?</h3>
        <p className="mb-8 text-xl text-gray-600">We offer comprehensive solutions tailored to your specific needs, leveraging our expertise in various domains.</p>
        <ServiceAccordion />
      </div>

      <ContactButton />
    </div>
  );
};

export default Product;