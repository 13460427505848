import React from 'react';
import aiEngineeringBackground from './assets/ai_engineering_bg.png'; // Ensure this image exists
import nlpImage from './assets/nlp.png';
import audioAiImage from './assets/audio_ai.png';
import visualModelImage from './assets/visual_ai.png';
import ContactButton from './ContactButton';
import EmpoweringImage from './assets/empowering_business.png';
const AIEngineering = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <header 
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ backgroundImage: `url(${aiEngineeringBackground})`, backgroundPosition: 'center' }}
                >
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        AI Engineering
                    </h1>
                    <p className="text-2xl text-black max-w-3xl mx-auto ">
                        Build intelligent applications and solutions tailored to your business needs.
                    </p>
                </header>

                <Section 
                    title="Empowering Businesses with AI"
                    image={EmpoweringImage}
                    alt="AI Engineering"
                >
                    <p className="text-lg mb-6">
                        Our AI Engineering services offer end-to-end solutions to transform your business. From natural language processing to custom chatbot development, we design products that interact seamlessly with your users. Our expertise in AI models, including visual and audio processing tools, ensures innovative solutions that deliver real-world impact.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our AI Capabilities</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={nlpImage}
                                title="Natural Language Processing"
                                description="Advanced NLP solutions to analyze and generate meaningful insights from textual data."
                            />
                            <Card
                                image={audioAiImage}
                                title="Audio AI"
                                description="High-quality audio AI tools, including text-to-speech and voice synthesis, to enhance user experiences."
                            />
                            <Card
                                image={visualModelImage}
                                title="Visual Models"
                                description="Powerful AI-driven visual models for image recognition, classification, and beyond."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default AIEngineering;
