import React from 'react'; 
import holisticImage from './assets/ai-data-analytics.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const AIDrivenDataAnalytics = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        AI-Driven Data Analytics
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Transform your data into actionable insights with Firebucks' AI-Driven Data Analytics services. Our expertise enables you to analyze large datasets with AI to uncover trends and insights that drive informed business decisions.
                    </p>
                </header>

                <Section
                    title="Advanced Predictive Analytics"
                    image={holisticImage}
                    alt="Advanced Predictive Analytics"
                >
                    <p className="text-lg mb-6">
                        Utilize our AI-powered predictive analytics solutions to forecast future trends and outcomes. By analyzing historical data, our models can predict future events, helping you to make proactive decisions and stay ahead of the competition.
                    </p>
                </Section>

                <Section
                    title="Anomaly Detection"
                    image={toolsImage}
                    alt="Anomaly Detection"
                    reverse
                >
                    <p className="text-lg mb-6">
                        Our AI-driven anomaly detection systems identify outliers and unusual patterns in your data. This is crucial for detecting fraud, ensuring data integrity, and maintaining the reliability of your operations. By using advanced machine learning algorithms, we can pinpoint anomalies in real-time, allowing for swift corrective actions.
                    </p>
                </Section>

                <Section
                    title="Data Visualization and Reporting"
                    image={industryImage}
                    alt="Data Visualization and Reporting"
                >
                    <p className="text-lg mb-6">
                        We provide comprehensive data visualization and reporting solutions that make it easy to understand complex datasets. Our interactive dashboards and detailed reports help you to visualize trends, correlations, and insights, making data-driven decision-making more accessible and effective.
                    </p>
                </Section>

                <Section
                    title="Business Intelligence"
                    image={chooseUsImage}
                    alt="Business Intelligence"
                    reverse
                >
                    <p className="text-lg mb-6">
                        Leverage our AI-driven business intelligence solutions to gain a competitive edge. Our tools integrate with your existing systems to provide real-time analytics and insights, empowering you to make strategic decisions based on accurate, up-to-date information.
                    </p>
                </Section>

                <Section
                    title="Custom AI Solutions"
                    image={holisticImage}
                    alt="Custom AI Solutions"
                >
                    <p className="text-lg mb-6">
                        At Firebucks, we tailor our AI solutions to meet your specific business needs. Whether you need predictive analytics, anomaly detection, or advanced data visualization, our team of experts will develop and implement AI-driven solutions that align with your business goals.
                    </p>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to harness the power of AI-Driven Data Analytics. Our comprehensive approach ensures that you can leverage AI to drive innovation, efficiency, and growth in your organization.
                    </p>
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our AI-Driven Data Analytics services and how we can help you transform your data into actionable insights.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

export default AIDrivenDataAnalytics;