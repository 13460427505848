import React, { useEffect, useRef, useState } from 'react';
import Geo from './assets/g.png'
const WhyChooseUs = () => {
  const featuresRef = useRef([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 972);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const animateIcons = () => {
      featuresRef.current.forEach((feature, index) => {
        setTimeout(() => {
          if (feature) {
            const paths = feature.querySelectorAll('path, circle, polyline, line, rect');
            paths.forEach(path => {
              const length = path.getTotalLength();
              path.style.strokeDasharray = length;
              path.style.strokeDashoffset = length;
              path.style.animation = `drawSVG 2s linear forwards`;
            });
          }
        }, index < 6 ? index * 300 : 1800); // Reduced delay to 300ms, last 4 items start at 1800ms
      });
    };

    animateIcons();
  }, []);

  const colors = [
    '#FF4500', // Orange Red
    '#4169E1', // Royal Blue
    '#4169E1', // Orange Red
    '#4169E1', // Royal Blue
    '#FF4500', // Orange Red
    '#1E90FF', // Dodger Blue
    '#FF4500', // Orange Red
    '#4682B4', // Steel Blue
    '#FF4500', // Orange Red
    '#4169E1', // Royal Blue
  ];

  const features = [
    {
      title: 'Innovative Engineering',
      svg: (
        <svg
          className="w-24  mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Light bulb representing innovation"
        >
          <path
            d="M32 12a12 12 0 00-12 12c0 6.627 5.373 12 12 12s12-5.373 12-12a12 12 0 00-12-12z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <line
            x1="32"
            y1="36"
            x2="32"
            y2="44"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <line
            x1="28"
            y1="48"
            x2="36"
            y2="48"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Experienced Team',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Team of professionals"
        >
          <circle
            cx="22"
            cy="24"
            r="8"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <circle
            cx="42"
            cy="24"
            r="8"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <path
            d="M14 44c0-6 4-10 8-10h20c4 0 8 4 8 10v4H14v-4z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Customized Approach',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Puzzle pieces representing customization"
        >
          <path
            d="M22 2h-8v8h8V2zM50 2h-8v8h8V2zM22 30h-8v8h8v-8zM50 30h-8v8h8v-8z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Agile Methodology',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Agile cycle"
        >
          <circle
            cx="32"
            cy="32"
            r="28"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <polyline
            points="32 4 32 32 60 32"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Quality Assurance',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Shield representing quality assurance"
        >
          <path
            d="M32 2l28 12v16c0 16-12 30-28 34C16 60 4 46 4 30V14L32 2z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <polyline
            points="20 34 28 42 44 26"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Competitive Pricing',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Dollar sign representing competitive pricing"
        >
          <path
            d="M32 4v56M20 18h24M20 46h24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Transparent Communication',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Speech bubbles representing communication"
        >
          <path
            d="M22 2h28v28H30l-8 8V2z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <path
            d="M14 34h-8v28l8-8h28V34H14z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Timely Delivery',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Clock representing timely delivery"
        >
          <circle
            cx="32"
            cy="32"
            r="28"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <polyline
            points="32 16 32 32 44 36"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Ongoing Support',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Lifebuoy representing ongoing support"
        >
          <circle
            cx="32"
            cy="32"
            r="28"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <circle
            cx="32"
            cy="32"
            r="12"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
    {
      title: 'Proven Track Record',
      svg: (
        <svg
          className="w-24 h-24 mx-auto mb-4 text-white"
          viewBox="0 0 64 64"
          aria-label="Trophy representing proven track record"
        >
          <path
            d="M20 2h24v24c0 8-4 14-12 14s-12-6-12-14V2z"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <line
            x1="12"
            y1="10"
            x2="20"
            y2="10"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <line
            x1="44"
            y1="10"
            x2="52"
            y2="10"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <rect
            x="24"
            y="40"
            width="16"
            height="6"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
          <rect
            x="22"
            y="46"
            width="20"
            height="4"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="animate-draw"
          />
        </svg>
      ),
    },
  ];

  const triangleLayout = [
    [features[0], features[1], features[2]],
    [features[3], features[4], features[5]],
    features.slice(6),
  ];

  return (
    <div 
      className="relative text-center py-12 px-4 bg-transparent min-h-[calc(100vh-75px)] your-next-section-class"
     style={{backgroundImage: `url(${Geo})` ,backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat',}}>
      <h2 className="text-4xl mb-12 font-bold text-gray-800">Why Choose Us</h2>
      <div class="border-t-2 border-gray-800 w-48 mx-auto mt-8"></div>
      <div className={` relative ${isMobile ? 'flex flex-col items-center mt-2' : 'w-full mt-24'}`} style={{ height: isMobile ? 'auto' : 'calc(100vh - 75px - 8rem)' }}>
        {triangleLayout.map((row, rowIndex) => (
          <div key={rowIndex} className={`${isMobile ? '' : 'absolute w-full mt-8'}`} style={isMobile ? {} : { top: `${rowIndex * 11}em` }}>
            {row.map((feature, colIndex) => feature && (
              <div
                className={`${isMobile ? 'mb-10' : 'absolute'} w-48 transform transition-all duration-500 hover:scale-110`}
                key={`${rowIndex}-${colIndex}`}
                ref={el => {
                  if (el) featuresRef.current.push(el);
                }}
                style={isMobile ? {} : {
                  left: `${(colIndex + 1) * (100 / (row.length + 1))}%`,
                  transform: 'translateX(-50%)',
                }}
              >
                {React.cloneElement(feature.svg, { 
                  className: `w-12 h-12 mx-auto mb-4 svg-icon`,
                  style: { color: colors[rowIndex * row.length + colIndex] }
                })}
                <h3 className="text-xl font-semibold" style={{ color: colors[rowIndex * row.length + colIndex] }}>{feature.title}</h3>
              </div>
            ))}
          </div>
        ))}
      </div>
      <style jsx>{`
        @keyframes drawSVG {
          to {
            stroke-dashoffset: 0;
          }
        }
        .svg-icon path,
        .svg-icon circle,
        .svg-icon polyline,
        .svg-icon line,
        .svg-icon rect {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
        }
      `}</style>
    </div>
  );
};

export default WhyChooseUs;
