import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import automationImage from './assets/comprehensive.png'
import integrationImage from './assets/additional.png'; 
import scalabilityImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const RoboticProcessAutomation = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Robotic Process Automation (RPA) Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Streamline your business processes and enhance efficiency with Firebucks' Robotic Process Automation (RPA) services. Our RPA solutions automate routine tasks, freeing up your team to focus on more strategic initiatives and drive business growth.
                    </p>
                </header>

                <Section
                    title="Automation of Repetitive Tasks"
                    image={automationImage}
                    alt="Automation of Repetitive Tasks"
                >
                    <p className="text-lg mb-6">Our RPA services enable automation of various routine and repetitive tasks across different business functions:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Finance and Accounting">
                            Automating invoice processing, payroll management, and financial reporting.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Human Resources">
                            Streamlining employee onboarding, benefits management, and compliance reporting.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customer Service">
                            Enhancing response times and accuracy by automating customer query handling and support ticket management.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Supply Chain Management">
                            Optimizing inventory control, order processing, and shipment tracking.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Integration with Existing Systems"
                    image={integrationImage}
                    alt="Integration with Existing Systems"
                    reverse
                >
                    <p className="text-lg mb-6">Our RPA solutions are designed to seamlessly integrate with your existing IT infrastructure, ensuring smooth operation and minimal disruption:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>
                            Utilizing APIs and other integration methods to connect RPA bots with legacy systems and modern applications alike.
                        </ListItem>
                        <ListItem icon={faCheckCircle}>
                            Ensuring data consistency and accuracy across systems through automated checks and balances.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Scalability and Flexibility"
                    image={scalabilityImage}
                    alt="Scalability and Flexibility"
                >
                    <p className="text-lg mb-6">Our RPA services provide the scalability necessary to handle increasing volumes of tasks and the flexibility to adapt to changing business needs:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>
                            Designing RPA solutions that can scale up or down based on demand without significant additional investment.
                        </ListItem>
                        <ListItem icon={faCheckCircle}>
                            Providing the ability to quickly modify or extend automation to new areas as business requirements evolve.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Opt for Firebucks for your RPA needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise">
                            Deep knowledge and extensive experience in implementing RPA solutions across various industries.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Efficiency Gains">
                            Significant improvements in process efficiency and reduction in operational costs.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Accuracy">
                            Reduced error rates through automation of routine tasks.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Robotic Process Automation services and how we can help you transform your business processes for better efficiency and accuracy.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default RoboticProcessAutomation;