import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png' 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const AIPoweredTestAutomation = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        AI-Powered Test Automation
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance your software testing processes with Firebucks' AI-Powered Test Automation services. Our expertise in leveraging AI ensures that your applications are tested more efficiently and accurately, reducing time-to-market and improving overall quality.
                    </p>
                </header>

                <Section
                    title="Comprehensive AI-Driven Testing"
                    image={holisticImage}
                    alt="Comprehensive AI-Driven Testing"
                >
                    <p className="text-lg mb-6">Our AI-Powered Test Automation services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Visual UI Testing">
                            Advanced AI-driven tools for precise detection of UI anomalies, reducing false positives.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Functional and UI Testing">
                            AI technologies to accelerate testing across different browsers and platforms.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Test Creation and Maintenance">
                            AI-enhanced algorithms for smart test creation, diagnosis, and maintenance.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Predictive Analytics">
                            AI algorithms to predict potential failure points and prioritize testing efforts.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced AI Tools and Techniques"
                    image={toolsImage}
                    alt="Advanced AI Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We leverage cutting-edge AI-powered tools to enhance various aspects of the testing lifecycle:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Visual AI for comprehensive visual validation</ListItem>
                        <ListItem icon={faCheckCircle}>Smart locators and AI-powered maintenance capabilities</ListItem>
                        <ListItem icon={faCheckCircle}>SmartFix for automatic test failure and UI change resolution</ListItem>
                        <ListItem icon={faCheckCircle}>AI-driven security and performance testing tools</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your AI-Powered Test Automation needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Cutting-edge AI Integration">
                            Leveraging the latest AI technologies for efficient and accurate testing.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Testing Strategy">
                            Covering visual, functional, security, and performance aspects of your application.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Improved Efficiency">
                            Reducing manual intervention and accelerating the testing process.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Quality Assurance">
                            Ensuring thorough testing and higher defect detection rates.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about how our AI-Powered Test Automation services can enhance your software development lifecycle.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default AIPoweredTestAutomation;