import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import efficientDataImage from './assets/comprehensive.png';
import advancedToolsImage from './assets/proven.png';
import proactiveStrategyImage from './assets/additional.png';
import chooseUsImage from './assets/choose_us.png';

const TestDataManagement = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Test Data Management Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Optimize your testing processes with Firebucks' Test Data Management services. Our services ensure that high-quality, secure, and relevant test data is available to meet the needs of your testing teams, helping you improve the accuracy and efficiency of your test cycles.
                    </p>
                </header>

                <Section
                    title="Efficient Data Provisioning"
                    image={efficientDataImage}
                    alt="Efficient Data Provisioning"
                >
                    <p className="text-lg mb-6">Our Test Data Management services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Data Masking">
                            Protecting sensitive data by creating anonymized versions that maintain data integrity without compromising privacy.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data Subsetting">
                            Creating smaller, manageable versions of data sets that are easier to use in testing environments while still being representative of the full data sets.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data Refresh and Cleanup">
                            Ensuring test data is current and relevant by regularly updating it and removing outdated or irrelevant data.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data Compliance">
                            Ensuring all test data adheres to regulatory compliance standards such as GDPR, HIPAA, or PCI DSS.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Automation"
                    image={advancedToolsImage}
                    alt="Advanced Tools and Automation"
                    reverse
                >
                    <p className="text-lg mb-6">We utilize state-of-the-art tools and automation techniques to streamline the management of test data:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated data masking tools to ensure data privacy and security.</ListItem>
                        <ListItem icon={faCheckCircle}>Test data generation tools to create realistic and diverse data sets for comprehensive testing.</ListItem>
                        <ListItem icon={faCheckCircle}>Data management software for efficient handling, storage, and retrieval of test data.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proactive Data Management Strategy"
                    image={proactiveStrategyImage}
                    alt="Proactive Data Management Strategy"
                >
                    <p className="text-lg mb-6">Our proactive approach ensures continuous improvement of test data management practices:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Regular audits and reviews of test data to ensure its quality and relevance.</ListItem>
                        <ListItem icon={faCheckCircle}>Feedback loops with testing teams to continuously enhance the test data provisioning process.</ListItem>
                        <ListItem icon={faCheckCircle}>Customizable data services to match the specific needs of different test environments and scenarios.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Test Data Management needs and benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            A dedicated team of data management professionals who specialize in test data solutions.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Service">
                            From data creation and maintenance to compliance and security, we cover all aspects of test data management.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Test Efficiency">
                            High-quality and relevant test data leads to faster, more accurate testing cycles.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Robust Data Security">
                            Strict adherence to data protection standards ensures the security of your test data.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Test Data Management services and how we can help streamline your testing processes with effective data solutions.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default TestDataManagement;