import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import threatModelingImage from './assets/comprehensive.png';
import customizedStrategiesImage from './assets/additional.png';
import proactiveProtectionImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const CyberSecurityThreatModeling = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Cyber Security and Threat Modeling Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Strengthen your cyber defenses with Firebucks' Cyber Security and Threat Modeling services. Our comprehensive approach helps you identify, assess, and mitigate potential security threats to protect your digital assets and ensure business continuity.
                    </p>
                </header>

                <Section
                    title="Advanced Threat Identification and Mitigation"
                    image={threatModelingImage}
                    alt="Advanced Threat Identification and Mitigation"
                >
                    <p className="text-lg mb-6">Our services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Threat Modeling">
                            Methodically analyzing your systems and applications to identify potential security threats at early stages.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Risk Assessment">
                            Evaluating the potential impact and likelihood of identified threats to prioritize mitigation efforts.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Architecture Review">
                            Examining your existing security architecture to identify weaknesses and recommend enhancements.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Incident Response Planning">
                            Developing strategic response plans to swiftly address and mitigate any security incidents.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Customized Security Strategies"
                    image={customizedStrategiesImage}
                    alt="Customized Security Strategies"
                    reverse
                >
                    <p className="text-lg mb-6">We tailor our cyber security solutions to meet the unique challenges of your organization:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Designing customized threat models based on your specific business processes and technologies.</ListItem>
                        <ListItem icon={faCheckCircle}>Integrating state-of-the-art security technologies to enhance your defensive posture.</ListItem>
                        <ListItem icon={faCheckCircle}>Providing training and awareness programs to ensure your team is equipped to recognize and respond to security threats.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proactive Protection and Compliance"
                    image={proactiveProtectionImage}
                    alt="Proactive Protection and Compliance"
                >
                    <p className="text-lg mb-6">Our proactive approach ensures your organization remains protected and compliant with industry standards:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Continuous Monitoring">
                            Implementing continuous monitoring solutions to detect and respond to threats in real-time.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Regulatory Compliance">
                            Ensuring your security practices meet regulatory requirements to avoid legal and financial repercussions.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Security Updates and Patch Management">
                            Keeping your systems updated with the latest security patches and updates to mitigate known vulnerabilities.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Cyber Security and Threat Modeling needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise">
                            Our team consists of experienced security professionals with extensive knowledge of the latest cyber threats and mitigation techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            We provide a full range of cyber security services from threat modeling to incident response and recovery.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Solutions">
                            Tailored security strategies that align with your specific business needs and risk profiles.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Cyber Security and Threat Modeling services and how we can help safeguard your organization against evolving cyber threats.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default CyberSecurityThreatModeling;