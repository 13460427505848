import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const UIUXTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        UI/UX Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance your application's user interface and user experience with Firebucks' comprehensive UI/UX Testing services. Our team ensures your product is intuitive, engaging, and meets the highest standards of usability.
                    </p>
                </header>

                <Section
                    title="Thorough Usability Testing"
                    image={holisticImage}
                    alt="Thorough Usability Testing"
                >
                    <p className="text-lg mb-6">Our UI/UX Testing services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Usability Testing">
                            Evaluating your application's ease of use, learning curve, and overall user satisfaction.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Accessibility Testing">
                            Ensuring your application is accessible to all users, including those with disabilities.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Design Consistency">
                            Verifying that all elements are visually and functionally consistent across the application.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Performance Testing">
                            Assessing the application's responsiveness and speed to ensure a smooth user experience.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Techniques"
                    image={toolsImage}
                    alt="Advanced Tools and Techniques"
                    reverse
                >
                    <p className="text-lg mb-6">We use the latest tools and methodologies for thorough UI/UX testing:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Heatmap tools like Hotjar and Crazy Egg to analyze user interaction</ListItem>
                        <ListItem icon={faCheckCircle}>User feedback and survey tools to gather real user insights</ListItem>
                        <ListItem icon={faCheckCircle}>Automated accessibility testing tools like Axe and WAVE</ListItem>
                        <ListItem icon={faCheckCircle}>Manual testing to assess design consistency and usability</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your UI/UX Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            Skilled testers proficient in the latest UI/UX testing tools and techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Coverage">
                            Extensive testing across usability, accessibility, design consistency, and performance.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="User-Centric Approach">
                            Focusing on real user needs and feedback to enhance your application's UI/UX.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Detailed Reporting">
                            Providing actionable insights and recommendations for improving UI/UX.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our UI/UX Testing services and how we can help enhance your application's user experience.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default UIUXTesting;