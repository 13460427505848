import React from 'react';
import generativeAITestingImage from './assets/generative-ai_header.png'; // Ensure this image exists
import generativeAITestingBackground from './assets/generative_ai_testing_bg.png'; // Ensure this image exists
import robustnessImage from './assets/delivery_future.png';
import ethicalAIImage from './assets/ethical_ai.png';
import securityTestingImage from './assets/ai_security.png';
import ContactButton from './ContactButton';

const GenerativeAITesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <header
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ backgroundImage: `url(${generativeAITestingBackground})`, backgroundPosition: 'center' }}
                >
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Generative AI Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Leading the way in digital assurance for generative AI solutions.
                    </p>
                </header>

                <Section
                    title="Pioneering Generative AI Assurance"
                    image={generativeAITestingImage}
                    alt="Generative AI Testing"
                >
                    <p className="text-lg mb-6">
                        At Firebucks, we set the industry standard for generative AI testing services. With cutting-edge methodologies and unmatched expertise, we ensure the robustness, ethical compliance, and reliability of your generative AI models. Our comprehensive solutions are designed to mitigate risks and drive innovation, making us the first choice for digital assurance in this transformative domain.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Generative AI Testing Capabilities</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={robustnessImage}
                                title="Robustness Testing"
                                description="Evaluate AI models against edge cases and adversarial inputs to ensure stability and reliability."
                            />
                            <Card
                                image={ethicalAIImage}
                                title="Ethical AI Testing"
                                description="Validate compliance with ethical standards and regulations, ensuring fair and unbiased AI systems."
                            />
                            <Card
                                image={securityTestingImage}
                                title="Security Testing"
                                description="Identify vulnerabilities in AI models to safeguard against data breaches and malicious exploitation."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default GenerativeAITesting;
