import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import comprehensiveImage from './assets/comprehensive.png';
import additionalImage from './assets/additional.png'; 
import chooseUsImage from './assets/choose_us.png';

const ServiceVirtualization = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Service Virtualization Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Accelerate your development and testing cycles with Firebucks' Service Virtualization services. By simulating various software components, our solutions enable continuous testing and development without delays caused by unavailable or limited-access services.
                    </p>
                </header>

                <Section
                    title="Comprehensive Virtualization Solutions"
                    image={comprehensiveImage}
                    alt="Comprehensive Virtualization Solutions"
                >
                    <p className="text-lg mb-6">Our service virtualization capabilities include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="API Virtualization">
                            Creating virtual versions of APIs to test against when actual APIs are not available or are cost-prohibitive to access during testing.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Environment Virtualization">
                            Simulating entire testing environments to allow for early and frequent performance and security testing.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Database Virtualization">
                            Providing access to virtual databases to enable testing of data-intensive applications without the need for full-scale production data.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of Service Virtualization"
                    image={additionalImage}
                    alt="Benefits of Service Virtualization"
                    reverse
                >
                    <p className="text-lg mb-6">Implementing service virtualization offers significant benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Reduced Development Time">
                            Enables parallel development and testing by removing dependencies on real services and environments.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Cost Efficiency">
                            Decreases the need for expensive hardware and software resources by using virtual services.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Quality Improvement">
                            Enhances the quality of applications by allowing more thorough and frequent testing.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Choose Firebucks for your Service Virtualization needs and benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expertise">
                            Our team has extensive experience in implementing complex service virtualization solutions across various industries.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Customized Virtualization Strategies">
                            We tailor our virtualization solutions to meet your specific development and testing needs.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Advanced Tools">
                            Utilizing the latest in service virtualization technology to provide robust, scalable, and reliable virtual services.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Service Virtualization services and how they can help streamline your development and testing processes.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default ServiceVirtualization;