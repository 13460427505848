import React from 'react'; 
import './contactUs.css';
import ContactFormX from './contact_form_contact_us'; // Assume this is your contact form component  
 
function ContactUs() {
  return (
    <div className="new-contact-us">
      <h1 className="contact-us-heading">We'd love to hear from you</h1>
      <p className="contact-us-subheading">
      Speak to an expert, request an assessment, demo our solutions
      </p>
      <p className="contact-us-subheading ">
     Or feel free to drop us a message at <a className='text-blue-500' href='mailto:mail@firebucks.ai'>mail@firebucks.ai</a>
      </p>
      <div className="new-contact-options">
        <div className="form-section">
          <ContactFormX />
        </div> 
 
      </div>
    </div>
  );
}

export default ContactUs;