import React from 'react';
import Expert from './assets/expert.png';

const ContactUsHero = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center p-4 md:p-8 lg:p-16 bg-gray-50">
      {/* Left Image Section */}
      <div className="w-full md:w-1/3 flex justify-center mb-6 md:mb-0">
        <img
          src={Expert} // Replace with the path to the image
          alt="Person deep in thought"
          className="rounded-full w-32 h-32 md:w-40 md:h-40 object-cover"
        />
      </div>

      {/* Right Text Section */}
      <div className="w-full md:w-2/3 text-center md:text-left">
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-900">
          Speak to an expert or request a demo. Find out what Firebucks can do for you.
        </h2>
        <p className="text-gray-600 mt-2 md:mt-4 font-semibold">
          Contact us now.
        </p>
        <p className="text-gray-500 mt-2">
          We'll answer your questions and provide a free consultation on how to move forward.
        </p>
        
        {/* Amazon-style Button */}
        <div className="mt-4 md:mt-6 flex justify-center md:justify-start">
          <button 
            onClick={() => window.location.href = '/contact-us'}
            className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold px-6 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition-transform transform hover:scale-105 duration-300"
          >
            GO
          </button>
        </div>
      </div> 
    </div>
  );
};

export default ContactUsHero;
