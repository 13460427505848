import React, { useEffect, useRef, useState, useCallback } from 'react';
import Team from './assets/about_us_khushi.png';
import AbousUsBG from './assets/about_us_bg_for_text.png';
import TeamAnsTestimonials from './about_us_second_section';

const AboutUsz = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const observerCallback = useCallback((entries) => {
    const entry = entries[0];
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 0.5 });
    const currentRef = sectionRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [observerCallback]);

  return (
    <div className="min-h-screen">
      {/* First Section with Zoom-in Background */}
      <div
        ref={sectionRef}
        className={`relative sm:h-[35vh] h-[50vh] flex flex-col items-center justify-center  text-white transition-transform duration-1000 ${
          isVisible ? 'scale-105' : 'scale-100'
        }`}
        style={{
          backgroundImage: `url('${AbousUsBG}')`, // replace with your background image path
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginTop: '60px',
        }}
      >
        <div className="absolute inset-0 bg-blue-900   opacity-50"></div>
        <div className="relative z-10 text-center px-4">
          <h1 className="text-4xl md:text-6xl font-bold">
          Curiosity. Creativity. Logic.
          </h1>
          <p className="mt-4 text-lg md:text-2xl">
          Solving challenges from the inside out.
          </p>
        </div>
      </div>

      {/* Second Section */}
      <div className="py-12 md:py-24 flex flex-col md:flex-row items-center justify-center bg-white text-black overflow-hidden">
        <div className="container h-auto mx-auto px-4 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-center gap-8 lg:gap-12">
            <div className="w-full md:w-1/2 lg:w-2/5">
              <img
                src={Team}
                alt="Team"
                className="object-cover mx-auto"
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-3/5 mt-8 md:mt-4">
              <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">Who We Are</h2>
              <h4 className="text-xl text-start md:text-2xl font-semibold mb-4"> We are not merely participants in the tech revolution; we are its architects.</h4>
              <p className="text-base text-justify md:text-lg">
                Our team, a constellation of dedicated professionals, is unwavering in its pursuit of enhancing the client
                experience. With a profound understanding of the intricate nuances of each business, we tailor our approach to
                meet and exceed expectations, ensuring a seamless journey towards success. By seamlessly integrating avant-garde
                technologies with a client-centric ethos, we empower businesses to transcend traditional boundaries, achieving
                unparalleled efficiency and transformative growth. In partnership with us, the future is not just a destination;
                it is a journey of continuous evolution and discovery.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative z-10 mt-8 md:mt-0">
        <TeamAnsTestimonials />
      </div>
    
    </div>
  );
};

export default AboutUsz;
