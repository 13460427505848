import React from 'react'; 
import digitalTransformationImage from './assets/digital_transformation.png'; // Ensure this image exists
import digitalTransformationBackground from './assets/digital_tranformation_background.png'; // Ensure this image exists
import cloudComputingImage from './assets/cloud_computing_image.png';
import aiMachineLearningImage from './assets/ai_machine_learning_image.png';
import dataAnalyticsImage from './assets/data_analytics_image.png';
import ContactButton from './ContactButton';

const DigitalTransformation = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <header 
                    className="relative bg-cover bg-center text-white lg:h-72 flex flex-col justify-center items-center mb-16 text-center"
                    style={{ 
                        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${digitalTransformationBackground})`,
                        backgroundPosition: 'center'
                    }}
                >
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-black">
                        Digital Transformation Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Transform your business with our cutting-edge digital solutions.
                    </p>
                </header>

                <Section 
                    title="Innovative Digital Solutions"
                    image={digitalTransformationImage}
                    alt="Digital Transformation"
                >
                    <p className="text-lg mb-6">
                        Our Digital Transformation services offer comprehensive solutions to modernize your business. We leverage the latest technologies to enhance efficiency and drive growth. Partner with us to unlock new opportunities and achieve your digital goals.
                    </p>
                </Section>

                <section className="w-full bg-gray-100 py-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Digital Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Card
                                image={cloudComputingImage}
                                title="Cloud Computing"
                                description="Scalable cloud solutions to enhance your business operations."
                            />
                            <Card
                                image={aiMachineLearningImage}
                                title="AI & Machine Learning"
                                description="Innovative AI and machine learning solutions to drive business intelligence."
                            />
                            <Card
                                image={dataAnalyticsImage}
                                title="Data Analytics"
                                description="Advanced data analytics to unlock insights and drive decision-making."
                            />
                        </div>
                    </div>
                </section>

                <ContactButton />
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt }) => (
    <section className="flex flex-col md:flex-row items-center mb-32">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

 
const Card = ({ image, title, description }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img src={image} alt={title} className="w-full h-48 object-contain" />
        <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

export default DigitalTransformation; 