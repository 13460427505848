import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import aiTestAutomation from './assets/ai-test-automation.png';
import impactBasedTesting from './assets/impact-based-testing.png';
import defectPrediction from './assets/defect-prediction.png';
import selfHealingScripts from './assets/self-healing-scripts.png';
import generativeAI from './assets/generative-ai.png';
import vectorDatabases from './assets/vector-databases.png';
import aiDataAnalytics from './assets/ai-data-analytics.png';
import modelMonitoring from './assets/model-monitoring.png';

const services = [
    {
        title: 'AI-Powered Test Automation',
        description: 'Automate your testing processes with AI-driven tools that ensure accuracy and efficiency. Our solutions reduce test maintenance effort by 30% and optimize test cycles by up to 40%.',
        icon: faArrowCircleRight,
        link: '/ai-powered-test-automation',
        image: aiTestAutomation
    },
    {
        title: 'Impact-Based Test Selection',
        description: 'Utilize machine learning algorithms to select and prioritize test cases based on their impact, ensuring that the most critical aspects are tested first.',
        icon: faArrowCircleRight,
        link: '/impact-based-test-selection',
        image: impactBasedTesting
    },
    {
        title: 'Defect Prediction and Analysis',
        description: 'Leverage AI to predict potential defects and perform root cause analysis, improving the efficiency of your development and testing teams.',
        icon: faArrowCircleRight,
        link: '/defect-prediction-analysis',
        image: defectPrediction
    },
    {
        title: 'Self-Healing Scripts',
        description: 'Our self-healing test scripts automatically adapt to changes in the application, minimizing the need for manual intervention.',
        icon: faArrowCircleRight,
        link: '/self-healing-scripts',
        image: selfHealingScripts
    },
    {
        title: 'Generative AI Models',
        description: 'Create new content, from text to images, using advanced generative AI models. Our expertise includes leveraging large language models (LLMs) like GPT.',
        icon: faArrowCircleRight,
        link: '/generative-ai-models',
        image: generativeAI
    },
    {
        title: 'Vector Databases',
        description: 'Implement vector databases to manage and retrieve high-dimensional data efficiently, crucial for AI applications like recommendation systems and semantic search.',
        icon: faArrowCircleRight,
        link: '/vector-databases',
        image: vectorDatabases
    },
    {
        title: 'AI-Driven Data Analytics',
        description: 'Analyze large datasets with AI to uncover insights and trends that drive business decisions. Our solutions include predictive analytics and anomaly detection.',
        icon: faArrowCircleRight,
        link: '/ai-driven-data-analytics',
        image: aiDataAnalytics
    },
    {
        title: 'Model Monitoring and Validation',
        description: 'Ensure the accuracy and fairness of your AI models through continuous monitoring and validation, addressing issues like bias and drift.',
        icon: faArrowCircleRight,
        link: '/model-monitoring-validation',
        image: modelMonitoring
    }
];

const AiMlSection = () => {
    return (
        <div className="container mx-auto px-4 py-8 sm:py-16 sm:mt-4">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold text-center pb-4 mb-6 mt-16 sm:mt-0 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                AI/ML Services
            </h1>
            
            <p className="text-center mb-8 sm:mb-16 text-base sm:text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
                At Firebucks, we leverage the latest advancements in AI and Machine Learning to provide cutting-edge solutions for both testing and development. Our services are designed to optimize efficiency, reduce costs, and improve overall product quality.
            </p>
            
            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">AI-Leveraged Testing</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-12 sm:mb-20">
                {services.slice(0, 4).map((service, index) => (
                    <ServiceCard key={index} service={service} />
                ))}
            </div>

            <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">AI Development</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-12 sm:mb-20">
                {services.slice(4).map((service, index) => (
                    <ServiceCard key={index} service={service} />
                ))}
            </div>
        </div>
    );
};

const ServiceCard = ({ service }) => (
    <div className="relative group overflow-hidden rounded-xl shadow-lg">
        <img 
            src={service.image}
            alt={service.title} 
            className="w-full h-48 sm:h-64 object-cover transition-transform duration-300 transform group-hover:scale-110"
        />
        <div className="absolute inset-x-0 top-0 bg-black bg-opacity-50 p-3 sm:p-4">
            <h3 className="text-xl sm:text-2xl font-bold text-white tracking-wide drop-shadow-lg">
                {service.title}
            </h3>
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center p-4 sm:p-6">
            <p className="text-white text-center text-sm sm:text-base">{service.description}</p>
        </div>
        <button className="absolute bottom-2 right-2 sm:bottom-4 sm:right-4 bg-white rounded-full p-2 shadow-lg" onClick={() => {
            window.location.href = service.link;
        }}>
            <FontAwesomeIcon icon={service.icon} className="text-blue-500 text-sm sm:text-base" />
        </button>
    </div>
);

export default AiMlSection;