import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const RootComponent = () => {
  useEffect(() => {
    // Prevent Clickjacking by breaking out of an iframe
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);

  return (
    <Router>
      <App />
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
