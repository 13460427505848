import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png' 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const PerformanceEngineering = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        Performance Engineering Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        At Firebucks, we specialize in Performance Engineering services that ensure your applications run smoothly and efficiently under all conditions. Our comprehensive approach focuses on optimizing performance, scalability, and reliability to meet your business needs.
                    </p>
                </header>

                <Section
                    title="Comprehensive Performance Strategy"
                    image={holisticImage}
                    alt="Comprehensive Performance Strategy"
                >
                    <p className="text-lg mb-6">Our Performance Engineering services include:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Performance Assessment">
                            Conducting thorough assessments to identify performance bottlenecks and areas for improvement.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Load Testing">
                            Simulating peak loads to ensure your application can handle high traffic and usage.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Stress Testing">
                            Pushing your application to its limits to identify potential points of failure.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Capacity Planning">
                            Developing strategies to ensure your application can scale effectively as demand grows.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proven Expertise and Tools"
                    image={toolsImage}
                    alt="Proven Expertise and Tools"
                    reverse
                >
                    <p className="text-lg mb-6">Our team utilizes the latest tools and technologies to enhance the effectiveness of our Performance Engineering services:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>JMeter and LoadRunner for load and stress testing</ListItem>
                        <ListItem icon={faCheckCircle}>New Relic and Dynatrace for performance monitoring and analytics</ListItem>
                        <ListItem icon={faCheckCircle}>Apache Bench and Gatling for benchmarking and scalability testing</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your Performance Engineering needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            A team of experienced professionals skilled in the latest performance engineering tools and techniques.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Custom Solutions">
                            Tailored performance strategies that meet your specific requirements.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Enhanced Efficiency">
                            Improved application performance, scalability, and reliability.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Quality Assurance">
                            Thorough and consistent performance testing to ensure optimal application performance.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our Performance Engineering services and how we can help you achieve your performance goals.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default PerformanceEngineering;