import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const ETLTesting = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        ETL Testing Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Ensure the accuracy and integrity of your data with Firebucks' ETL Testing services. Our comprehensive approach to ETL testing guarantees that data is correctly extracted, transformed, and loaded into the target systems, helping you make data-driven decisions with confidence.
                    </p>
                </header>

                <Section
                    title="Thorough Data Validation"
                    image={holisticImage}
                    alt="Thorough Data Validation"
                >
                    <p className="text-lg mb-6">Our ETL Testing services focus on:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Data Extraction">
                            Ensuring that data is accurately extracted from source systems without any loss.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data Transformation">
                            Verifying that transformation rules are correctly applied to prepare data for its intended use.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data Loading">
                            Confirming that data is correctly loaded into the target system, and validating its integrity and completeness.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Advanced Tools and Automation"
                    image={toolsImage}
                    alt="Advanced Tools and Automation"
                    reverse
                >
                    <p className="text-lg mb-6">We use leading tools and technologies to automate and enhance the ETL testing process:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Automated data comparison tools to quickly identify discrepancies between source and target systems.</ListItem>
                        <ListItem icon={faCheckCircle}>Data quality assessment tools to ensure high standards of data cleanliness and accuracy.</ListItem>
                        <ListItem icon={faCheckCircle}>Performance testing tools to evaluate the efficiency of ETL processes and optimize them for faster data processing.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Proactive Problem Resolution"
                    image={industryImage}
                    alt="Proactive Problem Resolution"
                >
                    <p className="text-lg mb-6">Our proactive approach includes:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Early detection of data issues during the ETL process to prevent data corruption.</ListItem>
                        <ListItem icon={faCheckCircle}>Regular updates and optimizations to ETL scripts and processes based on testing outcomes.</ListItem>
                        <ListItem icon={faCheckCircle}>Comprehensive reporting to provide insights into ETL process performance and issue resolution.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Partner with Firebucks for your ETL Testing needs to benefit from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Team">
                            A team of data and testing experts experienced in managing complex ETL projects.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Data-Driven Assurance">
                            Comprehensive testing strategies that ensure your data is reliable and actionable.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Custom Solutions">
                            Tailored testing approaches to meet the specific needs of your data projects.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to learn more about our ETL Testing services and how we can help ensure the integrity of your data migrations and integrations.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default ETLTesting;