import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import holisticImage from './assets/comprehensive.png'
import industryImage from './assets/additional.png'; 
import toolsImage from './assets/proven.png';
import chooseUsImage from './assets/choose_us.png';

const TCoEAssessment = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-4xl md:text-7xl font-extrabold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
                        TCOE Assessment Services
                    </h1>
                    <p className="text-2xl text-gray-600 max-w-3xl mx-auto">
                        Enhance your testing capabilities and operational efficiency with Firebucks' TCoE Assessment services. Our experts evaluate and optimize your testing processes, tools, and strategies to establish a highly efficient Testing Center of Excellence tailored to your business needs.
                    </p>
                </header>

                <Section
                    title="Comprehensive TCoE Evaluation"
                    image={holisticImage}
                    alt="Comprehensive TCoE Evaluation"
                >
                    <p className="text-lg mb-6">Our TCoE Assessment includes:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Current State Analysis">
                            Evaluating your existing testing practices, infrastructure, and resources to understand the baseline performance.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Gap Analysis">
                            Identifying gaps in your current testing processes and comparing them against industry best practices and standards.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Recommendations for Optimization">
                            Providing actionable recommendations to bridge gaps, enhance efficiency, and improve effectiveness.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Tools and Frameworks"
                    image={toolsImage}
                    alt="Tools and Frameworks"
                    reverse
                >
                    <p className="text-lg mb-6">We utilize a range of advanced tools and frameworks to support our TCoE assessments:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle}>Industry-standard assessment frameworks to ensure a comprehensive evaluation.</ListItem>
                        <ListItem icon={faCheckCircle}>Customized tools for data collection and analysis to provide deeper insights into your testing operations.</ListItem>
                        <ListItem icon={faCheckCircle}>Automated tools for continuous monitoring and improvement post-assessment.</ListItem>
                    </ul>
                </Section>

                <Section
                    title="Benefits of Establishing a TCoE"
                    image={industryImage}
                    alt="Benefits of Establishing a TCoE"
                >
                    <p className="text-lg mb-6">Establishing a Testing Center of Excellence provides significant benefits:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Standardized Testing Processes">
                            Creating consistent testing procedures across the organization.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Improved Quality and Efficiency">
                            Enhancing the quality of testing outputs while reducing costs and time.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Increased Innovation">
                            Fostering an environment that encourages innovation and continuous improvement in testing practices.
                        </ListItem>
                    </ul>
                </Section>

                <Section
                    title="Why Choose Firebucks?"
                    image={chooseUsImage}
                    alt="Why Choose Firebucks"
                    reverse
                >
                    <p className="text-lg mb-6">Opt for Firebucks for your TCoE Assessment to gain from:</p>
                    <ul className="space-y-4">
                        <ListItem icon={faCheckCircle} title="Expert Guidance">
                            Our team comprises experts with extensive experience in setting up and optimizing Testing Centers of Excellence.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Tailored Assessments">
                            We customize our assessment services to match your organization's unique challenges and objectives.
                        </ListItem>
                        <ListItem icon={faCheckCircle} title="Comprehensive Support">
                            We provide end-to-end support, from initial assessment to implementation of recommendations and beyond.
                        </ListItem>
                    </ul>
                </Section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Contact us today to discover how our TCoE Assessment services can help transform your testing function into a strategic asset for your organization.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Get in Touch
                    </button>
                </div>
            </div>
        </div>
    );
};

const Section = ({ title, children, image, alt, reverse }) => (
    <section className={`flex flex-col md:flex-row items-center mb-32 ${reverse ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <img src={image} alt={alt} className="w-full h-auto rounded-lg" />
        </div>
        <div className="w-full md:w-1/2 md:px-8">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">{title}</h2>
            {children}
        </div>
    </section>
);

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            {title && <strong className="text-lg">{title}:</strong>} {children}
        </div>
    </li>
);

export default TCoEAssessment;