import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCode, faLock, faChartLine } from '@fortawesome/free-solid-svg-icons';
import cicdImage from './assets/ci.png'; // Make sure to add this image

const ContinuousIntegration = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-6xl font-bold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-blue-500 to-indigo-500">
                        CI/CD Services
                    </h1>
                    <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                        Streamline your software development lifecycle with Firebucks' robust Continuous Integration and Continuous Deployment (CI/CD) services.
                    </p>
                </header>

                <section className="flex flex-col md:flex-row items-center mb-32">
                    <div className="w-full md:w-1/2 mb-8 md:mb-0">
                        <img src={cicdImage} alt="CI/CD Pipeline" className="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-8">
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Our CI/CD Expertise</h2>
                        <ul className="space-y-4">
                            <ListItem icon={faRocket} title="Automated Pipelines">
                                We implement and optimize automated pipelines using tools like Jenkins, GitLab CI, CircleCI, and Travis CI.
                            </ListItem>
                            <ListItem icon={faCode} title="Automated Testing">
                                We integrate unit tests, integration tests, and end-to-end tests to catch issues early in the development cycle.
                            </ListItem>
                            <ListItem icon={faLock} title="Security Integration">
                                We incorporate security checks and vulnerability assessments using tools like OWASP Dependency-Check and Aqua Security.
                            </ListItem>
                            <ListItem icon={faChartLine} title="Continuous Monitoring">
                                We set up monitoring tools like Prometheus, Grafana, and ELK Stack for real-time insights into application performance.
                            </ListItem>
                        </ul>
                    </div>
                </section>

                <section className="text-gray-700 space-y-6">
                    <p>Our CI/CD services begin with a thorough assessment of your current development and deployment processes. We identify bottlenecks and areas for improvement, then design and implement tailored CI/CD pipelines that align with your business goals.</p>
                    <p>For deployment automation, we leverage tools like Docker, Kubernetes, and Ansible. These technologies enable us to create consistent, repeatable deployment processes that can be scaled across multiple environments.</p>
                    <p>Partner with Firebucks for your CI/CD needs and experience the benefits of a streamlined, efficient, and secure software delivery process. Our expertise in CI/CD practices ensures that your applications are delivered faster, with higher quality, and with greater reliability.</p>
                </section>

                <div className="mt-16 text-center">
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-purple-500 via-blue-500 to-indigo-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Contact Us for CI/CD Services
                    </button>
                </div>
            </div>
        </div>
    );
};

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-indigo-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            <strong className="text-lg">{title}:</strong> {children}
        </div>
    </li>
);

export default ContinuousIntegration;