import React, { useState } from 'react';
import './contactForm.css';

function ContactFormX() {
  const [formData, setFormData] = useState({
    email: '',
    comment: ''
  });

  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const validate = () => {
    const errors = {};
    if (!formData.email) errors.email = 'Email is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid';
    return errors;
  };

  const errors = validate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      setSubmitStatus(null);

      const emailData = {
        sender: {
          name: "Website Visitor",
          email: "samdash.com@gmail.com"
        },
        to: [
          {
            email: "mail@firebucks.ai",
            name: "Website visitor"
          }
        ],
        subject: `${formData.email} visited the website`,
        htmlContent: formData.comment
      };

      fetch('https://api.brevo.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'api-key': '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',
          'content-type': 'application/json'
        },
        body: JSON.stringify(emailData)
      })
      .then(response => response.json())
      .then(data => {
        console.log('Email sent successfully:', data);
        setSubmitStatus('success');
        setFormData({
          email: '',
          comment: ''
        });
        setTouched({});
      })
      .catch(error => {
        console.error('Error sending email:', error);
        setSubmitStatus('error');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    } else {
      setTouched({
        email: true
      });
    }
  };

  return (
    <div className="contact-form-container">
      {submitStatus === 'success' ? (
        <div className="success-message">
          <h2>Thank you for contacting us!</h2>
          <p>Your message has been sent successfully. We'll get back to you soon.</p>
        </div>
      ) : (
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2 className="form-title">Contact Us</h2>
          <div className="form-group">
            <label className={formData.email ? 'filled' : ''} htmlFor="email">Email *</label>
            <input 
              id="email"
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleInputChange} 
              onBlur={handleBlur} 
              required 
            />
            {touched.email && errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label className={formData.comment ? 'filled' : ''} htmlFor="comment">Comment or Question</label>
            <textarea 
              id="comment"
              name="comment" 
              rows="4" 
              value={formData.comment} 
              onChange={handleInputChange} 
              onBlur={handleBlur} 
            />
          </div>
          <div className="form-group">
            <button 
              type="submit" 
              className="contact-submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? <span className="loader"></span> : 'Send Message'}
            </button>
          </div>
          {submitStatus === 'error' && (
            <p className="error-message">There was an error sending your message. Please try again later.</p>
          )}
        </form>
      )}
    </div>
  );
}

export default ContactFormX;