import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import agileImage from './assets/agile.png'; // Make sure to add this image

const Agile = () => {
    return (
        <div className="bg-white min-h-screen">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                <header className="mb-16 text-center">
                    <h1 className="text-3xl md:text-6xl font-bold text-center pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-teal-500 to-green-500">
                        Agile Transformation
                    </h1>
                    <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                        Embrace agility with Firebucks' Agile Transformation Services. We specialize in helping organizations transition to agile methodologies, enhancing their flexibility and responsiveness to ever-evolving market demands.
                    </p>
                </header>

                <section className="flex flex-col md:flex-row items-center mb-32">
                    <div className="w-full md:w-1/2 mb-8 md:mb-0">
                        <img src={agileImage} alt="Agile Transformation" className="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-8">
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Our Comprehensive Approach</h2>
                        <ul className="space-y-4">
                            <ListItem icon={faCheckCircle} title="Expert Implementation">
                                We implement frameworks like Scrum, Kanban, and SAFe (Scaled Agile Framework), tailored to your unique business needs.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Training and Workshops">
                                We facilitate agile workshops and training sessions to equip your teams with the necessary skills and knowledge.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Tools and Metrics">
                                We utilize industry-leading tools and establish agile metrics and KPIs to monitor progress and measure success.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Cultural Transformation">
                                We focus on fostering a culture of collaboration and continuous feedback, promoting adaptability and innovation.
                            </ListItem>
                            <ListItem icon={faCheckCircle} title="Agile Scaling">
                                For larger enterprises, we implement frameworks like SAFe to coordinate multiple agile teams effectively.
                            </ListItem>
                        </ul>
                    </div>
                </section>

                <div className="mt-16 text-center">
                    <p className="text-xl text-gray-700 mb-8">
                        Partner with Firebucks to navigate the complexities of Agile Transformation. Let's transform your business together, driving sustainable growth and innovation.
                    </p>
                    <button 
                        onClick={() => window.location.href = '/contact-us'}
                        className="bg-gradient-to-r from-blue-500 via-teal-500 to-green-500 text-white px-8 py-4 rounded-lg text-xl font-semibold hover:opacity-90 transition duration-300"
                    >
                        Contact Us Today
                    </button>
                </div>
            </div>
        </div>
    );
};

const ListItem = ({ icon, title, children }) => (
    <li className="flex items-start">
        <FontAwesomeIcon icon={icon} className="text-green-500 mt-1 mr-3 flex-shrink-0" />
        <div className="text-left">
            <strong className="text-lg">{title}:</strong> {children}
        </div>
    </li>
);

export default Agile;